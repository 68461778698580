import { ErrorState, RootState } from '../../redux/AppReducer';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { BrandVM, sortOptionType } from '../../common/view-model';
import { Events, Product } from '../../common/model';
import './BrandProfileComponent.scss';
import { FC, useCallback, useEffect, useState } from 'react';
import { aboutBrandState } from './model';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GET_PRODUCTS } from '../../graphql/queries/products';
import { AppActions } from '../../redux/AppActions';
import { GoBackButtonComponent } from '../UI/GoBackButtonComponent/GoBackButtonComponent';
import { useQuery } from '@apollo/client';
import { serverError } from '../../common/constants';
import LockOverlayComponent from '../LockOverlay/LockOverlayComponent';
import { ContactInformationComponent } from '../product-detail/accordion/contactInformation/ContactInformationComponent';
import BrandHeaderComponent from './brand-header-component/BrandHeaderComponent';
import BrandProductsComponent from './brand-products-component/BrandProductsComponent';

interface IBrandProfileComponentProps {
	brand: BrandVM;
	handleUnauthorized: () => void;
	handleError: (error: ErrorState) => void;
	currentEvent?: Events | null;
	setLockModalContent: () => void;
}
const BrandProfileComponent: FC<IBrandProfileComponentProps> = ({
	brand,
	handleError,
	handleUnauthorized,
	currentEvent
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [state] = useState({ ...aboutBrandState });
	const { ingredientsTemplateId } = useSelector((state: RootState) => state.appState?.userState!);
	const { sortBySelected, sortByOptions } = useSelector(
		(state: RootState) => state.appState?.filterState!
	);
	const { isLoadingData } = useSelector((state: RootState) => state.appState);
	const isLoadingCallback = useCallback(
		(loading: boolean) => dispatch(AppActions.setIsLoadingData(loading)),
		[dispatch]
	);
	const [products, setProducts] = useState<Product[]>([]);
	const sortBy =
		sortBySelected?.sortType === sortOptionType.MOST_RELEVANT
			? sortByOptions?.find(x => x.sortType === sortOptionType.ALPHABETICAL)
			: sortBySelected;

	const { loading, data, error } = useQuery(GET_PRODUCTS, {
		variables: {
			genericFilters: [
				{
					key: 'Brand',
					ids: [brand.id]
				}
			],
			sortAttribute: sortBy?.productSort.attribute,
			sortDirection: sortBy?.productSort.order,
			templateId: String(ingredientsTemplateId),
			expoEventId: currentEvent?.id
		},
		skip: currentEvent === undefined
	});
	useEffect(() => {
		isLoadingCallback(true);
		if (!error && !loading && data?.products?.products) {
			setProducts(data?.products?.products);
			isLoadingCallback(false);
		}
		if (error?.message === 'Unauthorized') {
			handleUnauthorized();
			return;
		}
		if (error) {
			handleError(serverError);
		}
	}, [loading, data, error, isLoadingCallback, handleUnauthorized, handleError]);

	const handleNavigateBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);
	return (
		<div data-testid='brand-profile-container' className='brand-profile-container'>
			<GoBackButtonComponent
				handelNavigate={handleNavigateBack}
				text={state.backToPageText}
				icon={<i className='fa fa-arrow-left' aria-hidden='true'></i>}
			/>
			<BrandHeaderComponent brand={brand} showShareMenu={true}></BrandHeaderComponent>
			{!isLoadingData ? (
				<BrandProductsComponent products={products} brand={brand}></BrandProductsComponent>
			) : null}
			<div className={`contact-container ${products.length <= 2 ? 'short-products-wrapper' : ''}`}>
				<div className='brand-contact-title-container'>
					<span className='brand-contact-title'>{state.contactBrandTitle}</span>
				</div>
				<ContactInformationComponent
					brokers={brand?.brokers}
					contact={{
						name: brand.contact_name,
						email: brand.contact_email,
						phone: brand.contact_phone
					}}
					productRep={brand?.prod_rep}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	currentEvent: state.storeState?.getCurrentEvent
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	handleUnauthorized: () => {
		return dispatch(AppActions.unauthorizedError());
	},
	handleError: (error: ErrorState) => {
		return dispatch(AppActions.setGeneralError(error));
	},
	setLockModalContent: () => {
		dispatch(
			AppActions.setModalContent({
				content: <LockOverlayComponent />,
				showCloseIcon: true,
				canScroll: false,
				closeOnDocumentClick: true
			})
		);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandProfileComponent);
