import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_BRAND } from '../../graphql/queries/brand';
import { AppState, ErrorState, RootState } from '../../redux/AppReducer';
import { BrandVM, ScreenType } from '../../common/view-model';
import { Dispatch, FC, useCallback, useEffect, useRef, useState } from 'react';
import { mapBrandProviderAttributes } from '../../components/brand-profile/model';
import { Action } from 'redux';
import { AppActions } from '../../redux/AppActions';
import { serverError } from '../../common/constants';
import './index.scss';
import BrandHeaderComponent from '../../components/brand-profile/brand-header-component/BrandHeaderComponent';
import { GoBackButtonComponent } from '../../components/UI/GoBackButtonComponent/GoBackButtonComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Swiper as ISwiper, Grid } from 'swiper';
import { useSelector } from 'react-redux';
import { ContactInformationComponent } from '../../components/product-detail/accordion/contactInformation/ContactInformationComponent';
const BrandBussinesProvider: FC = (props: any) => {
	const navigate = useNavigate();
	const { brandId } = useParams();
	const [brand, setBrand] = useState<BrandVM | undefined>();
	const swiperRef = useRef<ISwiper>();
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const currentEvent = props.appState.storeState?.getCurrentEvent;
	// TO-DO: temporary call to brand detail endpoint, needs to be changed when brand provider endpoint is active.
	const { data, loading, error } = useQuery<any>(GET_BRAND, {
		variables: { id: brandId, expoEventId: currentEvent?.id },
		skip: currentEvent === undefined
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!error && !loading && data?.brand) {
			const { __typename, ...rest } = data.brand;
			setBrand(mapBrandProviderAttributes(rest));
		}
		if (error?.message === 'Unauthorized') {
			props.handleUnauthorized();
		}
		if (error) {
			props.handleError(serverError);
		}
	}, [data, error, loading]);

	const handleNavigateBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	return (
		<>
			{brand && (
				<div className='brand-bussiness-profile-container'>
					<GoBackButtonComponent
						handelNavigate={handleNavigateBack}
						text={'Back'}
						icon={<i className='fa fa-arrow-left' aria-hidden='true'></i>}
					/>

					<BrandHeaderComponent brand={brand} showShareMenu={false}></BrandHeaderComponent>

					{brand.offerings && (
						<div className='container-offerings'>
							<span className='brand-info-title'>Our Offerings</span>
							<div className='container-options-offerings'>
								{brand.offerings?.map((offer, index) => (
									<div className='offering' key={`offer_${index}`}>
										<span className='title'>{offer.category}</span>
										<ul className='options'>
											{offer.offers?.map((category: any, index_cat: any) => (
												<li key={`category_${index_cat}`}>{category}</li>
											))}
										</ul>
									</div>
								))}
							</div>
						</div>
					)}
					<div className={`contact-container`}>
						<div className='brand-contact-title-container'>
							<span className='brand-contact-title'>Contact Information</span>
						</div>
						<ContactInformationComponent
							brokers={brand?.brokers}
							contact={{
								name: brand.contact_name,
								email: brand.contact_email,
								phone: brand.contact_phone,
								role: 'Test for role'
							}}
							productRep={brand.prod_rep}
						/>
					</div>

					{brand.clients && (
						<div className='clients-container'>
							<div className='title'>Our Clients</div>
							<div className='images-container'>
								{brand.clients?.map((client, index) => (
									<div className='image-wrapper' key={`client_image_${index}`}>
										<img className='image' src={client} alt='' />
									</div>
								))}
							</div>
						</div>
					)}

					{brand.testimonials && (
						<div className='text-container'>
							<span className='title'>Brand Testimonial</span>
							<div className='slider-container'>
								{deviceScreen !== ScreenType.MOBILE ? (
									<>
										<Swiper
											slidesPerView={1}
											spaceBetween={0}
											navigation={false}
											pagination={{
												clickable: true
											}}
											modules={[Pagination, Navigation]}
											onBeforeInit={swiper => {
												swiperRef.current = swiper;
											}}>
											{brand.testimonials?.map((testimonial, index) => (
												<SwiperSlide key={`testimonial_${index}`}>
													<div className='swiper-content'>
														<div className='description'>{`"${testimonial.description}"`}</div>
														<div className='author'>
															{`- ${testimonial.author} @ ${testimonial.company}`}
														</div>
													</div>
												</SwiperSlide>
											))}
										</Swiper>
										<>
											<button
												data-testid='desktop-swiper-arrow-prev'
												className='swiper-button-prev'
												onClick={() => swiperRef.current?.slidePrev()}></button>
											<button
												data-testid='desktop-swiper-arrow-next'
												className='swiper-button-next'
												onClick={() => swiperRef.current?.slideNext()}></button>
										</>
									</>
								) : (
									<>
										<Swiper
											data-testid='mobile-swiper'
											observeParents={true}
											slidesPerView={1}
											navigation={true}
											pagination={{
												el: '.swiper-custom-pagination-for-brand',
												clickable: true,
												dynamicBullets: false
											}}
											modules={[Grid, Pagination]}
											onBeforeInit={swiper => {
												swiperRef.current = swiper;
											}}>
											{brand.testimonials?.map((testimonial, index) => (
												<SwiperSlide key={`testimonial_${index}`}>
													<div className='swiper-content'>
														<div className='description'>{`"${testimonial.description}"`}</div>
														<div className='author'>
															{`- ${testimonial.author} @ ${testimonial.company}`}
														</div>
													</div>
												</SwiperSlide>
											))}
										</Swiper>
										<>
											<div data-testid='mobile-swiper-thumb'>
												<div
													className='swiper-custom-pagination-for-brand'
													style={{
														marginRight: 'auto',
														marginLeft: 'auto'
													}}></div>
											</div>

											<div data-testid='mobile-swiper-arrows' className='control-mobile-for-brand'>
												<i
													className='bi bi-chevron-left'
													onClick={() => {
														swiperRef.current?.slidePrev();
													}}></i>
												<i
													className='bi bi-chevron-right'
													onClick={() => {
														swiperRef.current?.slideNext();
													}}></i>
											</div>
										</>
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: AppState) => ({
	...state
});
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	handleUnauthorized: () => {
		return dispatch(AppActions.unauthorizedError());
	},
	handleError: (error: ErrorState) => {
		return dispatch(AppActions.setGeneralError(error));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandBussinesProvider);
