import './BrandFormComponent.scss';
import { useEffect, useState } from 'react';
import { BrandInfo, IProfileState, RootState } from '../../../../redux/AppReducer';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ScreenType } from '../../../../common/view-model';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import { CountryType } from '../../../authentication/user-profile/model';
import SelectFormComponent from '../../GenericBaseForms/SelectFormComponent/SelectFormComponent';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import { validationMessages, validationRegex } from '../../../../common/constants';
import {
	validateExactLengthMessage,
	validateMessageMax
} from '../../../../helpers/formFieldValidations';
import { DEFAULT_LABELS } from './model';
import LoadingBobComponent from '../../GenericBaseForms/LoadingBobComponent/LoadingBobComponent';
import { DEFAULT_PLACEHOLDERS } from '../model';
interface IBrandForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (userState: BrandInfo) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
	onChange: (data: any) => void;
	onValidationChange: (isValid: boolean) => void;
	cleanForm: boolean;
	updateKeyFc: () => void;
	profileState: IProfileState;
}
const BrandFormSchema = Yup.object().shape({
	brandHQCity: Yup.string()
		.min(4, validationMessages.min_chars)
		.required(validationMessages.field_required),
	brandHQState: Yup.object({
		name: Yup.string(),
		id: Yup.string()
	}),
	brandHQCountry: Yup.object({
		name: Yup.string(),
		id: Yup.string()
	}),
	brandYearFound: Yup.number()
		.test(
			'length',
			validateExactLengthMessage('4 digits.'),
			val => val !== undefined && val?.toString().length === 4
		)
		.max(new Date().getFullYear(), validateMessageMax(new Date().getFullYear().toString())),
	privateLabelServices: Yup.boolean(),
	brandVideo: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(150, validationMessages.max_value + '150.'),
	brandWebsite: Yup.string()
		.matches(validationRegex.url_regex, validationMessages.invalid_url)
		.max(150, validationMessages.max_value + '150.'),
	ownedByHoldingCompany: Yup.boolean(),
	holdingCompany: Yup.string().notRequired().max(50, validationMessages.max_value),
	ownedByParentCompany: Yup.boolean(),
	parentCompany: Yup.string().notRequired().max(50, validationMessages.max_value),
	otherServicesNeeds: Yup.array(),
	processingTechniques: Yup.array()
});
const LARGE_MOBILE = 744;
const SMALL_MOBILE = 500;
export const BrandFormComponent: React.FC<IBrandForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	onChange,
	onValidationChange,
	cleanForm,
	updateKeyFc,
	profileState
}) => {
	const ID = 'brand-details';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const [storyExpanded, setStoryExpanded] = useState<boolean>(false);
	const [missionExpanded, setMissionExpanded] = useState<boolean>(false);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const windowsSize = useWindowSize().width;
	const [charactersShown, setCharactersShown] = useState<number>(0);
	const [countriesList, setCountriesList] = useState<CountryType[]>([]);
	const [countryStates, setCountryStates] = useState<CountryType[]>([]);
	const [statesOptions, setStatesOptions] = useState<any[]>([]);
	const [maxTechniques, setMaxTechniques] = useState<boolean>(false);
	const [activeAttr, setActiveAttr] = useState(true);
	const [activeTech, setActiveATech] = useState(true);
	const validateCompany = (values: any) => {
		const errors: any = {};
		if (values.ownedByHoldingCompany && values.holdingCompany.length === 0) {
			errors.holdingCompany = validationMessages.field_required;
		}
		if (values.ownedByParentCompany && values.parentCompany.length === 0) {
			errors.parentCompany = validationMessages.field_required;
		}
		return errors;
	};
	const {
		errors,
		values,
		handleChange,
		handleReset,
		submitForm,
		setFieldValue,
		isValid,
		setValues
	} = useFormik({
		initialValues: {
			...brandState,
			brandHQCity: brandState.brandHQCity ? brandState.brandHQCity : '',
			brandHQCountry: {
				id: '',
				name: brandState.brandHQCountry
			},
			brandHQState: {
				id: '',
				name: brandState.brandHQState
			},
			brandYearFound: brandState.brandYearFound ? brandState.brandYearFound : 0,
			brandMission: brandState.brandMission ? brandState.brandMission : '',
			brandStory: brandState.brandStory ? brandState.brandStory : '',
			privateLabelServices: brandState.privateLabelServices,
			brandVideo: brandState.brandVideo,
			brandWebsite: brandState.brandWebsite ? brandState.brandWebsite : '',
			ownedByHoldingCompany: brandState.ownedByHoldingCompany,
			holdingCompany: brandState.holdingCompany,
			ownedByParentCompany: brandState.ownedByParentCompany,
			parentCompany: brandState.parentCompany,
			otherServicesNeeds: brandState.otherServicesNeeds,
			processingTechniques: brandState.processingTechniques,
			brandAttributes: brandState.brandAttributes
		},
		onSubmit: values => {
			onUpdateProfileBrand({
				...brandState,
				brandHQCity: values.brandHQCity,
				brandHQCountry: values.brandHQCountry.name,
				brandHQState: values.brandHQState.name,
				brandYearFound: values.brandYearFound,
				privateLabelServices: values.privateLabelServices,
				brandVideo: values.brandVideo,
				brandWebsite: values.brandWebsite,
				ownedByHoldingCompany: values.ownedByHoldingCompany,
				holdingCompany: values.holdingCompany,
				ownedByParentCompany: values.ownedByParentCompany,
				parentCompany: values.parentCompany,
				otherServicesNeeds: values.otherServicesNeeds,
				processingTechniques: values.processingTechniques,
				brandAttributes: values.brandAttributes
			});
			updateKeyFc();
		},
		validate: validateCompany,
		validationSchema: BrandFormSchema
	});

	useEffect(() => {
		if (isEditing) {
			setValues({
				...brandState,
				brandHQCity: brandState.brandHQCity ? brandState.brandHQCity : '',
				brandHQCountry: {
					id: '',
					name: brandState.brandHQCountry
				},
				brandHQState: {
					id: '',
					name: brandState.brandHQState
				},
				brandYearFound: brandState.brandYearFound ? brandState.brandYearFound : 0,
				brandMission: brandState.brandMission ? brandState.brandMission : '',
				brandStory: brandState.brandStory ? brandState.brandStory : '',
				privateLabelServices: brandState.privateLabelServices,
				brandVideo: brandState.brandVideo,
				brandWebsite: brandState.brandWebsite ? brandState.brandWebsite : '',
				ownedByHoldingCompany: brandState.ownedByHoldingCompany,
				holdingCompany: brandState.holdingCompany,
				ownedByParentCompany: brandState.ownedByParentCompany,
				parentCompany: brandState.parentCompany,
				otherServicesNeeds: brandState.otherServicesNeeds,
				processingTechniques: brandState.processingTechniques,
				brandAttributes: brandState.brandAttributes
			});
		}
	}, [brandState, isEditing]);

	const onServicesChange = (service: any) => {
		if (service.id !== 'noneAbove') {
			const updateServices = values.otherServicesNeeds!.map(serv =>
				serv.id === service.id
					? {
							...serv,
							isActive: !serv.isActive
					  }
					: serv
			);
			const hasActiveServices = updateServices.some(serv => serv.isActive === true);
			const services = updateServices.map(serv =>
				serv.id === 'noneAbove'
					? {
							...serv,
							isActive: !hasActiveServices
					  }
					: serv
			);
			setValues({ ...values, otherServicesNeeds: services });
		} else {
			const updateService = values.otherServicesNeeds!.map(item =>
				item.id === service.id
					? {
							...item,
							isActive: true
					  }
					: {
							...item,
							isActive: false
					  }
			);
			setValues({ ...values, otherServicesNeeds: updateService });
		}
	};
	const cleanInput = (inputType: string) => {
		if (inputType === 'processingTechnique') {
			const updatedTechniques = values.processingTechniques!.map(tech => ({
				...tech,
				isActive: false
			}));
			setActiveATech(false);
			setValues({
				...values,
				processingTechniques: updatedTechniques
			});
		} else if (inputType === 'attributes') {
			const updatedItems = values.brandAttributes!.map(item => ({
				...item,
				isActive: false
			}));
			setActiveAttr(false);
			setValues({
				...values,
				brandAttributes: updatedItems
			});
		}
	};

	const handleToggle = (type: string) => {
		if (type === 'story') {
			setStoryExpanded(!storyExpanded);
		} else {
			setMissionExpanded(!missionExpanded);
		}
	};
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		onChange(values);
	}, [brandState, values, errors, onChange]);

	useEffect(() => {
		onValidationChange(isValid);
	}, [isValid]);

	useEffect(() => {
		handleReset(null);
	}, [brandState]);
	useEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setCharactersShown(42);
		} else if (deviceScreen === ScreenType.TABLET) {
			setCharactersShown(25);
		} else if (LARGE_MOBILE < windowsSize && windowsSize > SMALL_MOBILE) {
			setCharactersShown(40);
		} else if (SMALL_MOBILE < windowsSize) {
			setCharactersShown(26);
		}
	}, [deviceScreen, windowsSize]);
	useEffect(() => {
		const hasActiveAttributes = values.brandAttributes.some(item => item.isActive === true);
		const hasProcesssingTechs = values.processingTechniques!.some(tech => tech.isActive === true);
		const maxTech = values.processingTechniques!.filter(tech => tech.isActive === true).length;
		if (maxTech >= 5) {
			setMaxTechniques(true);
		} else {
			setMaxTechniques(false);
		}
		setActiveATech(hasProcesssingTechs);
		setActiveAttr(hasActiveAttributes);
	}, [values]);
	useEffect(() => {
		const hasActiveAttributes = brandState.brandAttributes.some(item => item.isActive === true);
		const hasProcesssingTechs = brandState.processingTechniques!.some(
			tech => tech.isActive === true
		);
		setActiveATech(hasProcesssingTechs);
		setActiveAttr(hasActiveAttributes);
	}, [brandState, isEditing]);
	useEffect(() => {
		if (window.IIRISProfileMgr) {
			window.IIRISProfileMgr.getTaxonomyData('countries?locale=en')?.then((data: any) => {
				setCountriesList(data.data);
			});
		}
	}, [window.IIRISProfileMgr]);

	useEffect(() => {
		const countryStates = countriesList.filter(country => country.states.length > 0);
		setCountryStates(countryStates);
	}, [countriesList]);
	useEffect(() => {
		const element = countriesList.find(item => item.name === values.brandHQCountry.name);
		if (element && element.states.length > 0) {
			setStatesOptions(element.states);
		} else {
			setStatesOptions([]);
		}
	}, [values.brandHQCountry, countryStates, countriesList]);
	return (
		<div className='brand-tab' id={ID}>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => submitForm()}
				titleText='Brand'
				isValid={isValid}
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
				profileState={profileState}
			></TitleForms>
			{ID === profileState.sectionId && profileState.loading ? (
				<LoadingBobComponent></LoadingBobComponent>
			) : (
				<>
					{isVisible &&
						(isEditing !== ID ? (
							<>
								<p className='description-form'>{DEFAULT_LABELS.info_text}</p>
								<ContainerForms
									FormComponent1={
										<>
											<div className='brand-logo-container'>
												<LabelForms text={DEFAULT_LABELS.brandLogo}></LabelForms>

												<div className='brand-image'>
													<div className='image-container'>
														<img src={brandState?.brandLogo} alt='brand' />
													</div>
												</div>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandHQCountry}></LabelForms>
												<p
													className={brandState?.brandHQCountry ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}
												>
													{brandState?.brandHQCountry}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandHQState}></LabelForms>
												<p
													className={brandState?.brandHQState ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}
												>
													{brandState?.brandHQState}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandHQCity}></LabelForms>
												<p
													className={brandState?.brandHQCity ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}
												>
													{brandState?.brandHQCity}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandYearFound}></LabelForms>
												<p
													className={brandState?.brandYearFound! > 0 ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}
												>
													{brandState?.brandYearFound! > 0 ? brandState?.brandYearFound : ''}
												</p>
											</div>
											<div className='mission-container'>
												<LabelForms text={DEFAULT_LABELS.brandMission}></LabelForms>
												<div>
													<p className={brandState?.brandMission ? '' : 'blank-field'}>
														{missionExpanded
															? brandState?.brandMission
															: brandState?.brandMission
															? `${brandState?.brandMission?.substring(0, charactersShown)}...`
															: ''}
													</p>
													{brandState?.brandMission && (
														<button onClick={() => handleToggle('mission')}>
															{missionExpanded ? '' : 'Expand'}
														</button>
													)}
												</div>
											</div>
											<div className='story-container'>
												<LabelForms text={DEFAULT_LABELS.brandStory}></LabelForms>
												<div>
													<p className={brandState?.brandStory ? '' : 'blank-field'}>
														{storyExpanded
															? brandState?.brandStory
															: brandState?.brandStory
															? `${brandState?.brandStory?.substring(0, charactersShown)}...`
															: ''}
													</p>
													{brandState?.brandStory && (
														<button onClick={() => handleToggle('story')}>
															{storyExpanded ? '' : 'Expand'}
														</button>
													)}
												</div>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandAttributes}></LabelForms>
												{activeAttr ? (
													brandState
														.brandAttributes!.filter(attribute => attribute.isActive)
														.map((attribute, index) => (
															<div className='checkbox-container' key={index}>
																<CheckboxFormComponent
																	key={index + `${attribute}`}
																	checkboxType='square'
																	name={`brandAttributes.${index}.value`}
																	checkboxText={
																		<div className='attributes-info-container'>
																			<p className='title-attribute'>{attribute.id}</p>
																		</div>
																	}
																	value={attribute.isActive}
																	onClick={() => toggleEditing(ID)}
																	disabled={true}
																	onChange={handleChange}
																></CheckboxFormComponent>
															</div>
														))
												) : (
													<CheckboxFormComponent
														key={'cleanAttr'}
														checkboxType='square'
														name={`cleanFormAttributes`}
														checkboxText={
															<div className=''>
																<p className='description-technique'>None</p>
															</div>
														}
														disabled
														value={!activeAttr}
														onClick={() => toggleEditing(ID)}
													></CheckboxFormComponent>
												)}
											</div>
										</>
									}
									FormComponent2={
										<>
											<div className='blank-space' />
											<div>
												<LabelForms text={DEFAULT_LABELS.privateLabelServices}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='privateLabelServices'
													value={brandState.privateLabelServices!}
													disabled={true}
													onChange={handleChange}
													onClick={() => toggleEditing(ID)}
												></CheckboxFormComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandVideo}></LabelForms>
												<p
													className={brandState?.brandVideo ? '' : 'blank-field'}
													onClick={() => toggleEditing(ID)}
												>
													{brandState?.brandVideo}
												</p>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.ownedByHoldingCompany}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='ownedByHoldingCompany'
													value={brandState.ownedByHoldingCompany!}
													disabled={true}
													onChange={handleChange}
													onClick={() => toggleEditing(ID)}
												></CheckboxFormComponent>
											</div>
											{brandState.ownedByHoldingCompany && (
												<div>
													<LabelForms text={DEFAULT_LABELS.holdingCompany}></LabelForms>
													<p
														className={brandState?.holdingCompany ? '' : 'blank-field'}
														onClick={() => toggleEditing(ID)}
													>
														{brandState.holdingCompany}
													</p>
												</div>
											)}
											<div>
												<LabelForms text={DEFAULT_LABELS.ownedByParentCompany}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='ownedByParentCompany'
													value={brandState.ownedByParentCompany!}
													disabled={true}
													onChange={handleChange}
													onClick={() => toggleEditing(ID)}
												></CheckboxFormComponent>
											</div>
											{brandState.ownedByParentCompany && (
												<div>
													<LabelForms text={DEFAULT_LABELS.parentCompany}></LabelForms>
													<p
														className={brandState?.parentCompany ? '' : 'blank-field'}
														onClick={() => toggleEditing(ID)}
													>
														{brandState.parentCompany}
													</p>
												</div>
											)}
											<div>
												<LabelForms text={DEFAULT_LABELS.otherServicesNeeds}></LabelForms>
												{brandState.otherServicesNeeds
													?.filter(service => service.isActive)
													.map((service, index) => {
														return (
															<CheckboxFormComponent
																key={index + `${service.id}`}
																checkboxType='square'
																name={`processingTechniques.${index}.value`}
																checkboxText={
																	<>
																		<p className='title-service'>{service.label}</p>{' '}
																	</>
																}
																onClick={() => toggleEditing(ID)}
																value={service.isActive}
																disabled={true}
																onChange={handleChange}
															></CheckboxFormComponent>
														);
													})}
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.processingTechniques}></LabelForms>
												{activeTech ? (
													brandState
														.processingTechniques!.filter(tech => tech.isActive)
														.map((technique, index) => (
															<CheckboxFormComponent
																key={index + `${technique.id}`}
																checkboxType='square'
																name={`processingTechniques.${index}.value`}
																checkboxText={
																	<div className='processing-info-container'>
																		<p className='title-technique'>{technique.name}</p>
																		{' - '}
																		<p className='description-technique'>{technique.description}</p>
																	</div>
																}
																onClick={() => toggleEditing(ID)}
																disabled
																value={technique.isActive}
															></CheckboxFormComponent>
														))
												) : (
													<CheckboxFormComponent
														key={`cleantech`}
														checkboxType='square'
														name={`cleanFormAttributes`}
														checkboxText={
															<div className=''>
																<p className='description-technique'>None</p>
															</div>
														}
														disabled
														value={!activeTech}
														onClick={() => toggleEditing(ID)}
													></CheckboxFormComponent>
												)}
											</div>
										</>
									}
								></ContainerForms>
							</>
						) : (
							<>
								<p className='description-form'>{DEFAULT_LABELS.info_text}</p>
								<ContainerForms
									FormComponent1={
										<>
											<div className='brand-logo-container'>
												<LabelForms text={DEFAULT_LABELS.brandLogo}></LabelForms>
												<div className='brand-image'>
													<div className='image-container'>
														<img src={brandState?.brandLogo} alt='brand' />
													</div>
												</div>
											</div>

											<div>
												<LabelForms text={DEFAULT_LABELS.brandHQCountry}></LabelForms>
												<SelectFormComponent
													items={countriesList}
													name='brandHQCountry'
													onChange={setFieldValue}
													value={values.brandHQCountry}
													placeholder={DEFAULT_PLACEHOLDERS.choose_list}
												></SelectFormComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandHQState}></LabelForms>
												<SelectFormComponent
													items={statesOptions}
													name='brandHQState'
													onChange={setFieldValue}
													value={values.brandHQState}
													placeholder={DEFAULT_PLACEHOLDERS.choose_list}
												></SelectFormComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandHQCity}></LabelForms>
												<TextInputComponent
													error={errors.brandHQCity?.length! > 0}
													errorHelperText={<div>{errors.brandHQCity}</div>}
													placeholder={DEFAULT_PLACEHOLDERS.city}
													name='brandHQCity'
													onChange={handleChange}
													value={values.brandHQCity!}
												></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandYearFound}></LabelForms>
												<TextInputComponent
													error={errors.brandYearFound?.length! > 0}
													errorHelperText={<div>{errors.brandYearFound}</div>}
													placeholder={DEFAULT_LABELS.brandYearFound}
													name='brandYearFound'
													onChange={handleChange}
													value={values.brandYearFound!}
													type='number'
												></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandMission}></LabelForms>
												<TextInputComponent
													error={errors.brandMission?.length! > 0}
													errorHelperText={<div>{errors.brandMission}</div>}
													placeholder={DEFAULT_LABELS.brandMission}
													name='brandMission'
													value={values.brandMission!}
													disabled
													type='textarea'
												></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandStory}></LabelForms>
												<TextInputComponent
													error={errors.brandStory?.length! > 0}
													errorHelperText={<div>{errors.brandStory}</div>}
													placeholder={DEFAULT_LABELS.brandStory}
													name='brandStory'
													value={values.brandStory!}
													disabled
													type='textarea'
												></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandAttributes}></LabelForms>
												{values.brandAttributes!.map((attribute, index) => (
													<CheckboxFormComponent
														key={index + attribute.id}
														checkboxType='square'
														name={`brandAttributes.[${index}].isActive`}
														checkboxText={
															<div className='attribute-info-container'>
																<p className='title-attribute'>{attribute.id}</p>
															</div>
														}
														value={attribute.isActive}
														disabled={false}
														onChange={(e: any) => {
															handleChange(e);
														}}
													></CheckboxFormComponent>
												))}
												<CheckboxFormComponent
													checkboxType='square'
													name={`cleanFormAttributes`}
													checkboxText={
														<div className=''>
															<p className='description-technique'>None</p>
														</div>
													}
													value={!activeAttr}
													onChange={() => cleanInput('attributes')}
												></CheckboxFormComponent>
											</div>
										</>
									}
									FormComponent2={
										<>
											<div className='blank-space' />
											<div>
												<LabelForms text={DEFAULT_LABELS.privateLabelServices}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='privateLabelServices'
													value={values.privateLabelServices!}
													disabled={false}
													onChange={handleChange}
												></CheckboxFormComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.brandVideo}></LabelForms>
												<TextInputComponent
													error={errors.brandVideo?.length! > 0}
													errorHelperText={<div>{errors.brandVideo}</div>}
													placeholder={DEFAULT_PLACEHOLDERS.brand_video}
													name='brandVideo'
													onChange={handleChange}
													value={values.brandVideo!}
												></TextInputComponent>
											</div>
											<div>
												<LabelForms text={DEFAULT_LABELS.ownedByHoldingCompany}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='ownedByHoldingCompany'
													value={values.ownedByHoldingCompany!}
													disabled={false}
													onChange={handleChange}
												></CheckboxFormComponent>
											</div>
											{values.ownedByHoldingCompany === true && (
												<div>
													<LabelForms
														text={DEFAULT_LABELS.holdingCompany}
														toolTipText={
															<>
																A holding company invests in operating companies that actually
																produce goods or offer services.
															</>
														}
														icon={<i className='ri-question-line' />}
														toolTipPosition='right'
													></LabelForms>
													<TextInputComponent
														error={errors.holdingCompany?.length! > 0}
														errorHelperText={<div>{errors.holdingCompany}</div>}
														placeholder={DEFAULT_PLACEHOLDERS.company_name}
														name='holdingCompany'
														onChange={handleChange}
														value={values.holdingCompany!}
													></TextInputComponent>
												</div>
											)}
											<div>
												<LabelForms text={DEFAULT_LABELS.ownedByParentCompany}></LabelForms>
												<CheckboxFormComponent
													checkboxType='toggle'
													name='ownedByParentCompany'
													value={values.ownedByParentCompany!}
													disabled={false}
													onChange={handleChange}
												></CheckboxFormComponent>
											</div>
											{values.ownedByParentCompany === true && (
												<div>
													<LabelForms
														text={DEFAULT_LABELS.parentCompany}
														toolTipText={
															<>
																A parent company has its own operations and also owns other
																companies.
															</>
														}
														icon={<i className='ri-question-line' />}
														toolTipPosition='right'
													></LabelForms>
													<TextInputComponent
														error={errors.parentCompany?.length! > 0}
														errorHelperText={<div>{errors.parentCompany}</div>}
														placeholder={DEFAULT_PLACEHOLDERS.company_name}
														name='parentCompany'
														onChange={handleChange}
														value={values.parentCompany!}
													></TextInputComponent>
												</div>
											)}
											<div>
												<LabelForms text={DEFAULT_LABELS.otherServicesNeeds}></LabelForms>
												{values.otherServicesNeeds!.map((service, index) => (
													<CheckboxFormComponent
														key={index + service.id}
														checkboxType='square'
														name={`otherServicesNeeds.[${index}].isActive`}
														checkboxText={<>{values.otherServicesNeeds![index].label}</>}
														value={service.isActive}
														disabled={false}
														onChange={() => onServicesChange(service)}
													></CheckboxFormComponent>
												))}
											</div>

											<div>
												<LabelForms text={DEFAULT_LABELS.processingTechniques}></LabelForms>
												{maxTechniques ? (
													<>
														{values.processingTechniques?.map((tech, index) => (
															<CheckboxFormComponent
																key={index + tech.id}
																checkboxType='square'
																name={`processingTechniques.[${index}].isActive`}
																checkboxText={
																	<div className='processing-info-container'>
																		<p className='title-technique'>{tech.name}</p>
																		{' - '}
																		<p className='description-technique'>{tech.description}</p>
																	</div>
																}
																value={tech.isActive}
																disabled={tech.isActive ? false : true}
																onChange={handleChange}
															></CheckboxFormComponent>
														))}
													</>
												) : (
													<>
														{values.processingTechniques!.map((technique, index) => (
															<CheckboxFormComponent
																key={index + technique.id}
																checkboxType='square'
																name={`processingTechniques.[${index}].isActive`}
																checkboxText={
																	<div className='processing-info-container'>
																		<p className='title-technique'>{technique.name}</p>
																		{' - '}
																		<p className='description-technique'>{technique.description}</p>
																	</div>
																}
																value={technique.isActive}
																disabled={false}
																onChange={handleChange}
															></CheckboxFormComponent>
														))}
													</>
												)}
												<CheckboxFormComponent
													checkboxType='square'
													name={``}
													checkboxText={<div className=''>None</div>}
													value={!activeTech}
													onChange={() => cleanInput('processingTechnique')}
												></CheckboxFormComponent>
											</div>
										</>
									}
								></ContainerForms>
								<ActionsForms
									onUpdate={() => submitForm()}
									onCancel={() => {
										onCancel();
										handleReset(null);
									}}
									isValid={isValid}
									toggleEditing={() => toggleEditing(null)}
								></ActionsForms>
							</>
						))}
				</>
			)}
		</div>
	);
};
