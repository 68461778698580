import { gql } from 'graphql.macro';

export const GET_BUSINESS_BRAND_BY_CATEGORY = gql`
	query GET_BRANDS_BY_BUSINESS_SERVICES($id: Int!) {
		brandsByBusinessService(categoryId: $id) {
			id
			name
			brands {
				id
				url_logo
				contact_name
				name
			}
		}
	}
`;
