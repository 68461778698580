export const otherServicesNeedsLabels = {
	contractManufacturingPrivateLabel: 'Contract Manufacturing / Legal Services',
	fulfillmentLogistics: 'Fulfillment / Logistics',
	packagingPlastics: 'Packaging / Plastics',
	sustainablePackaging: 'Sustainable Packaging',
	labelPrinting: 'Label Printing',
	qualityAssurance: 'Quality Assurance / Control / Lab Testing',
	certifyingAgentOrganic: 'Certifying Agent for GMPs Organic Gluten Free Non-GMO etc',
	formulationClinicalResearch: 'Formulation / R&D / Clinical Research',
	mediaMarketingAgency: 'PR / Media / Marketing Agency',
	manufacturingProcessingEquipment: 'Manufacturing / Processing Equipment',
	legalRegulatoryConsulting: 'Legal / Regulatory Consulting',
	contactlessSampling: 'Contactless Sampling',
	staffingServices: 'Staffing Services',
	financeAccountingPayrollServices: 'Finance / Accounting Payroll Services',
	financialInstitutionInvestmentBanking: 'Financial Institution/Investment Banking',
	investors: 'Investors',
	onlineBusinessSolutions: 'Online Business Solutions',
	artificialIntelligence: 'Artificial Intelligence',
	seekDistribution: 'Seek Distribution',
	seekBrokerWholesaler: 'Seek Broker/Wholesaler',
	otherNotListedHere: 'Other - Not listed here',
	noneAbove: 'None of the above'
};

export const otherServicesNeedsArray = [
	{ id: 'advertisingPRMediaMarketingAgencies', label: 'Advertising/PR/Media/Marketing Agencies' },
	{ id: 'advisorGroups', label: 'Advisor Groups' },
	{ id: 'amazonSpendEtailerExperts', label: 'Amazon Spend/E-tailer Experts' },
	{ id: 'certifiers', label: 'Certifiers' },
	{ id: 'consultants', label: 'Consultants' },
	{ id: 'consultingGroups', label: 'Consulting Groups' },
	{ id: 'contractManufacturingPrivateLabel', label: 'Contract Manufacturers/Private Label' },
	{ id: 'diagnostics', label: 'Diagnostics' },
	{ id: 'ecommerceMediaPlatform', label: 'Ecommerce Media Platform' },
	{ id: 'educationTraining', label: 'Education/Training' },
	{ id: 'equipmentManufacturing', label: 'Equipment Manufacturing' },
	{ id: 'etailers', label: 'E-tailers' },
	{ id: 'financialInstitutions', label: 'Financial Institutions' },
	{ id: 'industryTech', label: 'Industry Tech' },
	{ id: 'inspection', label: 'Inspection' },
	{ id: 'investmentBanking', label: 'Investment Banking' },
	{ id: 'labs', label: 'Labs' },
	{ id: 'legalRegulationCompliance', label: 'Legal/Regulation/Compliance' },
	{ id: 'packagingNew', label: 'Packaging' },
	{ id: 'plasticsNew', label: 'Plastics' },
	{ id: 'printingLabelsNew', label: 'Printing/Labels' },
	{ id: 'qualityAssuranceQualityControlNew', label: 'Quality Assurance/Quality Control' },
	{ id: 'samplingProvidersNew', label: 'Sampling Providers' },
	{ id: 'sasPlatformsVendorsNew', label: 'SAS Platforms/Vendors' },
	{ id: 'searchEnginePlatformsNew', label: 'Search Engine Platforms' },
	{ id: 'shippingLogisticsNew', label: 'Shipping/Logistics' },
	{ id: 'shopperMarketingNew', label: 'Shopper Marketing' },
	{ id: 'sustainabilityESGProvidersNew', label: 'Sustainability/ESG Providers' },
	{ id: 'testingNew', label: 'Testing' },
	{ id: 'tradeSolutionsNew', label: 'Trade Solutions' },
	{ id: 'virtualBoothTradeShowProvidersNew', label: 'Virtual Booth/Trade Show Providers' },
	{ id: 'otherNotListedHere', label: 'Other - Not listed here' },
	{ id: 'noneAbove', label: 'None' }
];

export const DEFAULT_LABELS = {
	info_text:
		'Some information will be seen by retailers via your brand profile page on Beacon Discovery. Please make sure that all information is accurate and up-to-date.',
	brandLogo: 'Brand Logo',
	brandHQCountry: 'Headquarters Country',
	brandHQState: 'Headquarters State',
	brandHQCity: 'Headquarters City',
	brandYearFound: 'Year Founded',
	brandMission: 'Brand Mission',
	brandStory: 'Brand Story',
	brandAttributes: 'Please select the attributes that represent your brand:',
	privateLabelServices: 'Do you offer private label services?',
	brandVideo: 'Brand Video',
	ownedByHoldingCompany: 'Are you owned by a holding company?',
	holdingCompany: 'Holding Company Name',
	ownedByParentCompany: 'Are you owned by a parent company?',
	parentCompany: 'Parent Company Name',
	otherServicesNeeds: 'Select any Business Services needs:',
	processingTechniques:
		'Are any of your products made using these processing techniques? Select up to 5.'
};
