import { connect, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { FC, useLayoutEffect, useRef, useState } from 'react';
import { Product } from '../../../common/model';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Swiper as ISwiper, Grid } from 'swiper';
import ProductComponent from '../../product/ProductComponent';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link, useNavigate } from 'react-router-dom';
import { BrandVM, FilterType, FilterVM, ScreenType } from '../../../common/view-model';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { Action, Dispatch } from 'redux';
import { UserActions } from '../../../redux/UserActions';
import { eventPublicPathName, publicBrandDetailActions } from '../../../common/constants';
import { FilterActions } from '../../../redux/FilterActions';
import { AppActions } from '../../../redux/AppActions';
import { getFilterTypeKeyForUrl } from '../../../helpers/urlParametersHelpers';
import './BrandProductsComponent.scss';

interface IBrandProductsComponentProps {
	products: Product[];
	brand: BrandVM;
	registerUserClickEvent: (event: string) => void;
}

const BrandProductsComponent: FC<IBrandProductsComponentProps> = ({
	products,
	brand,
	registerUserClickEvent
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const swiperRef = useRef<ISwiper>();
	const { favoriteProducts } = useSelector((state: RootState) => state.appState?.userState!);
	const { deviceScreen } = useSelector((state: RootState) => state.appState!);
	const [desktopSlidePerview, setDesktopSlidePerview] = useState(6);
	const filter: FilterVM = {
		id: brand.id,
		name: brand.name,
		children: undefined,
		isChecked: true,
		type: FilterType.BRAND
	};
	useLayoutEffect(() => {
		if (deviceScreen === ScreenType.LARGE_DESKTOP) {
			setDesktopSlidePerview(6);
		} else if (deviceScreen === ScreenType.MEDIUM_DESKTOP) {
			setDesktopSlidePerview(5);
		} else if (deviceScreen === ScreenType.SMALL_DESKTOP) {
			setDesktopSlidePerview(4);
		} else if (deviceScreen === ScreenType.TABLET) {
			setDesktopSlidePerview(3);
		}
	}, [deviceScreen]);
	const handleViewProductsClk = () => {
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicBrandDetailActions.CLICK_SEE_ALL_PRODUCTS);
		}
		dispatch(FilterActions.removeAllAndAddFilter(filter));
		dispatch(AppActions.setProductsCached([]));
		dispatch(AppActions.setUserReturns(false));
		dispatch(AppActions.setPage(0));
		sessionStorage.setItem('scrollPosition', '' + 0);
		navigate(
			`${
				!isCurrentUserAnonymous() ? '/products?' : `/${eventPublicPathName}/products?`
			}${getFilterTypeKeyForUrl(FilterType.BRAND)}=${brand.id}`
		);
	};

	return (
		<div className='brand-products'>
			<div>
				<Link
					to={`${
						!isCurrentUserAnonymous() ? '/products?' : `/${eventPublicPathName}/products?`
					}${getFilterTypeKeyForUrl(FilterType.BRAND)}=${brand.id}`}
					data-testid='go-to-brand-link'
					className='brand-products-link'
					onClick={handleViewProductsClk}
				>
					{`View All ${brand.name}'s Products`}
					<div>
						<i className='ri-arrow-right-line icon-arrow'></i>
					</div>
				</Link>
			</div>

			<div className='products-carousel-container'>
				{deviceScreen !== ScreenType.MOBILE ? (
					<>
						<Swiper
							data-testid='desktop-swiper'
							slidesPerView={desktopSlidePerview}
							spaceBetween={24}
							navigation={false}
							pagination={{
								clickable: true
							}}
							modules={[Pagination, Navigation]}
							onBeforeInit={swiper => {
								swiperRef.current = swiper;
							}}
						>
							{products.map((product: Product, index: number) => (
								<SwiperSlide key={index}>
									<ProductComponent
										product={product}
										favorite={favoriteProducts?.some(favorite => favorite.pid === product?.id)}
										isOnBrandPage={true}
										anonymousEventName={publicBrandDetailActions.CLICK_HEART_ICON}
									/>
								</SwiperSlide>
							))}
						</Swiper>
						{products.length > desktopSlidePerview && (
							<>
								<button
									data-testid='desktop-swiper-arrow-prev'
									className='swiper-button-prev'
									onClick={() => swiperRef.current?.slidePrev()}
								></button>
								<button
									data-testid='desktop-swiper-arrow-next'
									className='swiper-button-next'
									onClick={() => swiperRef.current?.slideNext()}
								></button>
							</>
						)}
					</>
				) : (
					<>
						{products.length > 0 && (
							<>
								<Swiper
									data-testid='mobile-swiper'
									observeParents={true}
									slidesPerView={2}
									spaceBetween={30}
									grid={{
										rows: 2,
										fill: 'row'
									}}
									navigation={true}
									pagination={{
										el: '.swiper-custom-pagination-for-brand',
										clickable: true,
										dynamicBullets: products.length > 20,
										dynamicMainBullets: 5
									}}
									modules={[Grid, Pagination]}
									onBeforeInit={swiper => {
										swiperRef.current = swiper;
									}}
								>
									{products.map((product: Product, index: number) => (
										<SwiperSlide key={index}>
											<ProductComponent
												product={product}
												favorite={favoriteProducts?.some(favorite => favorite.pid === product?.id)}
												isOnBrandPage={true}
												anonymousEventName={publicBrandDetailActions.CLICK_HEART_ICON}
											/>
										</SwiperSlide>
									))}
								</Swiper>
								{products.length > 4 && (
									<>
										<div data-testid='mobile-swiper-thumb'>
											<div
												className='swiper-custom-pagination-for-brand'
												style={{
													marginRight: products.length > 20 ? '0px' : 'auto',
													marginLeft: products.length > 20 ? '0px' : 'auto'
												}}
											></div>
										</div>

										<div data-testid='mobile-swiper-arrows' className='control-mobile-for-brand'>
											<i
												className='bi bi-chevron-left'
												onClick={() => {
													swiperRef.current?.slidePrev();
												}}
											></i>
											<i
												className='bi bi-chevron-right'
												onClick={() => {
													swiperRef.current?.slideNext();
												}}
											></i>
										</div>
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	currentEvent: state.storeState?.getCurrentEvent
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandProductsComponent);
