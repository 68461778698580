import { useEffect, useState } from 'react';
import './MenuProfileComponent.scss';
import {
	DEFAULT_USER_MENU,
	USER_ADMIN_MENU,
	IMenuProps,
	IMenuItem,
	DEFAULT_FULL_USER_MENU,
	IMenuItemOptions
} from './model';
import { PROFILE_ROUTES } from '../../../common/constants';
import { UserType } from '../../../common/model';
import { findUserAccessType } from '../../../helpers/findUserAccessType';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import { buildTrackingBobPayload } from '../../../helpers/handleBobTracking';
import { TrackingEventType } from '../../../common/view-model';
import { handleTracking } from '../../../helpers/handelTracking';

export const MenuProfileComponent: React.FC<IMenuProps> = ({ onSelectedItem, selectedItem }) => {
	const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);
	const [menuItems, setMenuItems] = useState<IMenuItem>(DEFAULT_USER_MENU);
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const toggleSubMenu = () => {
		setOpenSubMenu(!openSubMenu);
	};
	const handleItemMenu = (index: any) => {
		onSelectedItem(index);
		if (
			index !== PROFILE_ROUTES.BRAND_INFO ||
			index !== PROFILE_ROUTES.PRODUCT_INFO ||
			index !== PROFILE_ROUTES.ONBOARD_FAQ
		) {
			setOpenSubMenu(false);
		}
	};

	const trackCallbackSubmenu = (index: string) => {
		if (index === PROFILE_ROUTES.BRAND_INFO) {
			const trackingPayload = buildTrackingBobPayload({
				type: TrackingEventType.BOB_TRACKING_MENU_BRAND,
				user: {
					id: userState?.id || '',
					type: userState.user_type || ''
				},
				event: {
					id: 'bob-tracking-menu',
					name: 'bob-tracking-menu',
					type: 'bob-tracking'
				}
			});
			handleTracking(trackingPayload);
		}
	};

	const trackCalbackMenu = (index: any) => {
		if (index === PROFILE_ROUTES.BEACON_HUB) {
			const trackingPayload = buildTrackingBobPayload({
				type: TrackingEventType.BOB_TRACKING_BEACON_HUB,
				user: {
					id: userState?.id || '',
					type: userState.user_type || ''
				},
				event: {
					id: 'bob-tracking-menu',
					name: 'bob-tracking-menu',
					type: 'bob-tracking'
				}
			});
			handleTracking(trackingPayload);
		}
	};
	useEffect(() => {
		const userFullAccess = findUserAccessType(userState?.user_type) !== UserType.Brand;
		if (userState && userState.isBrandAdmin !== undefined) {
			const menuItemsSelection: IMenuItemOptions = userState.isBrandAdmin
				? USER_ADMIN_MENU
				: userFullAccess
				? DEFAULT_FULL_USER_MENU
				: DEFAULT_USER_MENU;
			setMenuItems(menuItemsSelection);
		}
	}, [userState]);

	return (
		<ul className='menu'>
			{Object.entries(menuItems).map(([key, value]) => {
				const isActive = selectedItem === value.path;
				const itemClass = isActive ? 'active' : '';
				const isOpen =
					openSubMenu ||
					selectedItem === PROFILE_ROUTES.BRAND_INFO ||
					selectedItem === PROFILE_ROUTES.PRODUCT_INFO ||
					selectedItem === PROFILE_ROUTES.GET_ON_BEACON;
				if (value.options) {
					return (
						<div key={key}>
							<div
								key={key}
								className={`tab-container title-submenu ${itemClass} ${isOpen ? 'active' : ''}`}
								onClick={() => {
									trackCalbackMenu(key);
									toggleSubMenu();
								}}
							>
								<li className={`tab-text `} key={key}>
									{value.title}
									<i className={isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} />
								</li>
							</div>
							<div className={`submenu ${isOpen ? 'show' : ''}`} key={`tab-submenu-${key}`}>
								<ul className='menu'>
									{isOpen &&
										Object.entries(value.options).map(([key, value]: any) => {
											const subMenuItemActive = selectedItem === value.path;
											const itemClassSub = subMenuItemActive ? 'active-sub' : '';
											return (
												<div
													key={`tab-container-${key}`}
													className={`tab-container ${itemClassSub}`}
													onClick={() => {
														trackCallbackSubmenu(value.path);
														handleItemMenu(value.path);
													}}
												>
													<li key={key} className={`tab-text`}>
														{value.title}
													</li>
												</div>
											);
										})}
								</ul>
							</div>
						</div>
					);
				}
				return (
					<div
						key={'default' + key}
						className={`tab-container ${itemClass}`}
						onClick={() => {
							trackCalbackMenu(key);
							handleItemMenu(value.path);
						}}
					>
						<li key={key} className={`tab-text`}>
							{value.title}
						</li>
					</div>
				);
			})}
		</ul>
	);
};
