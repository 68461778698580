import { Action } from 'redux-actions';
import {
	Certification,
	Distributor,
	Product,
	Region,
	Taxonomy,
	Allergen,
	ConsumerDiet,
	Packaging,
	Attribute,
	AnimalWelfare,
	Climate,
	BusinessPractice,
	Events,
	Brand
} from '../common/model';
import {
	FilterVM,
	IModalContent,
	IngredientsTemplate,
	ISortByOption,
	PintoFilters,
	ScreenType,
	IToastState,
	ISuggestion,
	IngredientCategory,
	ICategoryNavbar,
	EngagementDashboard,
	IHighlightedProducts,
	IAppFilter
} from '../common/view-model';
import { ISelectedFilterState } from '../components/filter/generic-filter/model';
import { mockFilters } from '../mock/filters';
import { ApolloActions } from './ApolloActions';
import { AppActions } from './AppActions';
import { AuthActions } from './AuthActions';
import { UserActions } from './UserActions';
import { ClaimIngredients } from '../common/model';
import { sortByOptions } from '../mock/sortByOptions';
import { FilterActions } from './FilterActions';
import { ScreenActions } from './ScreenActions';
import { ToastActions } from './toast/ToastActions';
import { filterSortOptionsById } from '../helpers/filterSortOptionsById';
import { isAuthenticated, isCurrentUserAnonymous } from '../helpers/authenticationHelper';
import { SearchActions } from './search/SearchActions';
import { IngredientFilterActions } from './ingredientFilter/IngredientFilterActions';
import { EngagementDashboardActions } from './engagementDashboard/EngagementDashboardActions';
import {
	distributorsArray,
	infoAttributes,
	processingTechniques,
	regionsArray,
	retailChannelsArray
} from '../common/constants';
import { otherServicesNeedsArray } from '../components/forms/BrandForms/BrandForm/model';

export interface RootState {
	appState: AppState;
}

export interface AppState {
	appFavourite?: boolean;
	appTitle?: string;
	authState?: AuthState;
	completeBrandDetailsLayout?: boolean;
	createBeaconProfileProgress?: number;
	deviceScreen?: ScreenType;
	errorState?: ErrorState;
	favouriteState?: FavouriteState;
	filterState?: FilterState;
	businessFilterState?: BusinessFilterState;
	hasCreatedBeaconProfile?: boolean;
	hasElementOverlay?: boolean;
	ingredientFilterState?: IngredientFilterState;
	isCompanyLoading?: boolean;
	isFavoriting?: boolean;
	isLoadingData?: boolean;
	isLoadingSaveUserBeacon?: boolean;
	isOnFavoritesPage?: boolean;
	isProfileLayout?: boolean;
	loadProfileProgress?: number;
	modalContent?: IModalContent;
	searchState?: SearchState;
	showNavbar?: boolean;
	standAloneLayout?: boolean;
	storeState?: StoreState;
	toastState?: IToastState;
	userState?: UserState;
	homePageLayout?: boolean;
	contactAccordionOpened?: boolean;
	dashboardState?: EngagementDashboardState;
	userBeaconInfo?: IUserBeaconInfo;
	showBackToTop?: boolean;
	profileState?: IProfileState;
}

export interface IProfileState {
	loading: boolean;
	sectionId: string;
}

export interface IUserBeaconInfo {
	origin: string | undefined;
	status: boolean | undefined;
}

export interface StoreState {
	allergens?: Allergen[];
	animalWelfares?: AnimalWelfare[];
	attributes?: Attribute[];
	businessPractices?: BusinessPractice[];
	certifications?: Certification[];
	claimIngredients?: ClaimIngredients[];
	climates?: Climate[];
	consumerDiets?: ConsumerDiet[];
	distributors?: Distributor[];
	getCurrentEvent?: Events | null;
	packagings?: Packaging[];
	page?: number;
	product?: Product;
	products: Product[];
	productsCached?: Product[];
	regions?: Region[];
	taxonomies?: Taxonomy[];
	taxonomyFilters?: FilterVM[];
	taxonomyNavbar?: FilterVM[];
	businessServicesNavbar?: any;
	templates?: IngredientsTemplate[];
	totalCount?: number;
	userReturns?: boolean;
	productsHighlighted?: IHighlightedProducts;
	brandsHighlighted?: Brand[];
	filters?: IAppFilter;
}
export interface AuthState {
	isAuthenticated: boolean;
	isRefreshingToken?: boolean;
}
export interface FilterState {
	selectedFilters?: FilterVM[];
	selectedCategory?: string;
	allFilters?: PintoFilters[];
	filterStates?: ISelectedFilterState[];
	sortByOptions?: ISortByOption[];
	sortBySelected?: ISortByOption;
	openFilterFunctionRef?: Function;
	onMouseLeaveCategoryNavbar?: Function;
	onFilterPanelOutsiteClick?: Function;
	isMobileFilterOpen?: boolean;
	carrotIconClicked?: boolean;
	lastDeletedFilter?: FilterVM;
	favoriteSortByOptions?: ISortByOption[];
	favoriteSortBySelected?: ISortByOption;
	breadCrumbElements?: string[];
	bubbleFunctionRef?: any[];
	currentFilterOpenIndex?: number;
	hasMappedAllFilters?: boolean;
	lastFilterUrl?: string;
	categoryNavbarElements?: ICategoryNavbar[];
}

export interface BusinessFilterState {
	selectedFilters?: any[];
	selectedCategory?: any;
}
export interface FavouriteState {
	isclicked?: boolean;
}

export interface ErrorState {
	hasError?: boolean;
	errorTitle?: string;
	errorContent?: string;
	errorSubContent?: string;
	errorImg?: string;
	hasFilterError?: boolean;
}

export interface FavoriteAddDate {
	pid: string;
	addDate: string;
}

export interface UserState {
	user?: any;
	ingredientsTemplateId?: number;
	favoriteProducts?: FavoriteAddDate[];
	companyDetails?: any;
	hasLoadProfile?: boolean;
	saveTemplateLoding?: boolean;
	isAnonymous?: boolean;
	hasLoadAnonymous?: boolean;
	hasAnonymousEnded?: boolean;
	hasClickOnFilter?: boolean;
	isBrandAdmin?: boolean;
	brandInfo?: BrandInfo;
	products?: ProductInfo[];
	subscribeAlertsStatus?: boolean | undefined;
	errorBob?: boolean;
}

export interface BrandDistributorRegion {
	distributor_id: string;
	distributor_name: string;
	region_id: string;
	region_name: string;
}

export interface BrandBroker {
	email: string;
	name: string;
	phone: string;
}

export interface BrandInfo {
	uniqueApplicationSessionId: string;
	salesforceAccount: string;
	name?: string;
	status?: string;
	brandLogo?: string;
	accountPrimaryContactName?: string;
	accountPrimaryContactEmail?: string;
	submittedContactEmail?: string;
	submittedContactName?: string;
	brandRepEmail?: string;
	brandRepName?: string;
	brandRepPhone?: string;
	brandRepTitle?: string;
	productRepEmail?: string;
	productRepName?: string;
	productRepPhone?: string;
	productRepTitle?: string;
	brandStory?: string;
	brandMission?: string;
	brandVideo?: string;
	brandWebsite?: string;
	brandYearFound?: number;
	holdingCompany?: string;
	brandHQCountry?: string;
	brandHQState?: string;
	brandHQCity?: string;
	haveABroker?: boolean;
	ownedByParentCompany?: boolean;
	ownedByHoldingCompany?: boolean;
	parentCompany?: string;
	privateLabelServices?: boolean;
	processingTechniques?: IProcessingTechniques[];
	brokers?: BrandBroker[];
	brandInstagram?: string;
	brandYoutube?: string;
	brandTwitter?: string;
	brandLinkedin?: string;
	brandFacebook?: string;
	brandTiktok?: string;
	currentRetailChannels: IChannel[];
	currentDistributionChannels: IChannel[];
	mapPolicy: boolean;
	otherServicesNeeds?: IOtherServicesNeeds[];
	keheRegion: IRegion[];
	UNFIRegion: IRegion[];
	otherDistributorRegion: IRegion[];
	brandAttributes: IBrandInfoAttributes[];
	activelyRaisingCapital?: boolean;
	revenueLastYear?: number;
	revenueExpectedThisYear?: number;
}

export interface ProductInfo {
	id: string;
	status: string;
	name: string;
	imageUrl: string;
	approvedOn: string;
	productCategory: string;
	productDescription: string;
	imagesLastUpdate: string;
	marketReady: boolean;
	currentYearNextyFinalist: boolean;
	currentYearNextyWinner: boolean;
	flagshipProduct: boolean;
	showHowGood: boolean;
	howGoodLabel: string;
	howGoodEstimated: number;
	howGoodAvgEstimated: number;
	dimensions: {
		[key: string]: string;
	};
	certifiedNonGMO: boolean;
	certifiedOrganic: boolean;
	certifiedGlutenFree: boolean;
	productMSRP: string;
	launchYear: string;
	productGTIN: string;
	productShelfLife: string;
	violations: {
		[key: string]: boolean;
	};
}
export interface IChannel {
	id: string;
	name: string;
	isActive: boolean;
}
export interface IRegion {
	id: string;
	name: string;
	isActive: boolean;
}

export interface IProcessingTechniques {
	id: string;
	name: string;
	description: string;
	isActive: boolean;
}

export interface IOtherServicesNeeds {
	id: string;
	label: string;
	isActive: boolean;
}

export interface IBrandInfoAttributes {
	id: string;
	isActive: boolean;
}

export interface StringIndexedObject {
	[key: string]: boolean | any;
}

export interface SearchState {
	hasClickedOutsideBar?: boolean;
	searchTerm?: ISuggestion;
	suggestions?: ISuggestion[];
	suggestionError?: boolean;
}
export interface IngredientFilterState {
	selectedFilter?: IngredientCategory;
	filterOptions?: IngredientCategory[];
}
export interface EngagementDashboardState {
	data?: EngagementDashboard;
	isLoading: boolean;
	hasError: boolean;
}

export const initialAppState: AppState = {
	isLoadingData: false,
	hasElementOverlay: false,
	appTitle: undefined,
	appFavourite: false,
	modalContent: undefined,
	deviceScreen: undefined,
	isLoadingSaveUserBeacon: false,
	showNavbar: true,
	loadProfileProgress: 0,
	isFavoriting: false,
	createBeaconProfileProgress: 0,
	hasCreatedBeaconProfile: false,
	isCompanyLoading: false,
	contactAccordionOpened: false,
	userBeaconInfo: {
		origin: undefined,
		status: undefined
	},
	showBackToTop: true,
	storeState: {
		products: [],
		productsCached: [],
		page: 0,
		userReturns: false
	},
	authState: {
		isAuthenticated: isAuthenticated(),
		isRefreshingToken: undefined
	},
	filterState: {
		selectedFilters: [],
		selectedCategory: undefined,
		allFilters: mockFilters,
		filterStates: [],
		sortByOptions: [...filterSortOptionsById([...sortByOptions], ['2', '3', '4', '5'])],
		sortBySelected: [...filterSortOptionsById([...sortByOptions], ['2', '3', '4', '5'])][0],
		favoriteSortByOptions: [...filterSortOptionsById([...sortByOptions], ['1', '2', '5', '3'])],
		favoriteSortBySelected: [...filterSortOptionsById([...sortByOptions], ['1', '2', '5', '3'])][0],
		breadCrumbElements: [],
		hasMappedAllFilters: false,
		categoryNavbarElements: []
	},
	businessFilterState: {
		selectedFilters: [],
		selectedCategory: undefined
	},
	userState: {
		user: undefined,
		ingredientsTemplateId: undefined,
		favoriteProducts: [],
		companyDetails: undefined,
		hasLoadProfile: false,
		isAnonymous: isCurrentUserAnonymous(),
		hasLoadAnonymous: isCurrentUserAnonymous(),
		hasAnonymousEnded: false,
		hasClickOnFilter: false,
		isBrandAdmin: false,
		brandInfo: undefined,
		subscribeAlertsStatus: undefined,
		errorBob: false
	},
	searchState: {
		hasClickedOutsideBar: false,
		suggestionError: false,
		suggestions: [],
		searchTerm: undefined
	},
	homePageLayout: window.location.pathname === '/home',
	dashboardState: {
		data: undefined,
		isLoading: false,
		hasError: false
	},
	profileState: {
		loading: false,
		sectionId: ''
	}
};

export const initialRootState: RootState = {
	appState: initialAppState
};
export const appReducer = (state: AppState = initialAppState, action: Action<any>): AppState => {
	switch (action.type) {
		case AppActions.SET_STATE:
			return {
				...state
			};
		case 'STORE_STATE':
			return {
				...state,
				isLoadingData: false,
				storeState: { ...state?.storeState, ...action.payload } as any
			};
		case 'STORE_STATE_PRODUCTS':
			return {
				...state,
				isLoadingData: false,
				storeState: {
					...state?.storeState,
					products: [...action.payload.products.products],
					totalCount: action.payload.products.totalCount
				} as any
			};
		case AppActions.SET_PRODUCTS_CACHED:
			return {
				...state,
				storeState: {
					...state?.storeState,
					productsCached: [...action.payload]
				} as any
			};
		case AppActions.SET_PAGE:
			return {
				...state,
				storeState: {
					...state?.storeState,
					page: action.payload
				} as any
			};
		case AppActions.SET_USER_RETURNS:
			return {
				...state,
				storeState: {
					...state?.storeState,
					userReturns: action.payload
				} as any
			};
		case 'END_STATE':
			return {
				...state
			};
		case ApolloActions.GRAPHQL:
			return {
				...state,
				isLoadingData: true
			};
		case AuthActions.LOGOUT:
			return {
				...state,
				authState: {
					isAuthenticated: false
				}
			};
		case 'FILTER_STATE':
			return {
				...state,
				filterState: {
					...state.filterState,
					selectedFilters: action.payload.newFilters as any,
					selectedCategory: action.payload.selectedCategory as any,
					lastDeletedFilter: action.payload.lastDeletedFilter,
					breadCrumbElements: action.payload.breadCrumbElements
				}
			};
		case FilterActions.BUSINESS_FILTER_STATE:
			return {
				...state,
				businessFilterState: {
					selectedCategory: action.payload
				}
			};
		case 'FILTER_CATEGORY_STATE':
			return {
				...state,
				filterState: {
					...state.filterState,
					selectedCategory: action.payload as any,
					lastDeletedFilter: state.filterState?.lastDeletedFilter,
					breadCrumbElements: undefined
				}
			};
		case 'CLEAR_ALL_FILTERS':
			return {
				...state,
				filterState: {
					...state.filterState,
					selectedFilters: [],
					selectedCategory: undefined,
					lastDeletedFilter: undefined
				}
			};
		case 'UPDATE_FILTER_STATE':
			return {
				...state,
				filterState: {
					...state.filterState,
					filterStates: action.payload as any,
					lastDeletedFilter: state.filterState?.lastDeletedFilter
				}
			};
		case 'OVERLAY_STATE': {
			return {
				...state,
				hasElementOverlay: action.payload as any
			};
		}
		case 'APP_TITLE': {
			return {
				...state,
				appTitle: action.payload as any
			};
		}
		case AppActions.SET_APP_FAVOURITE: {
			return {
				...state,
				appFavourite: action.payload as any
			};
		}
		case 'IS_LOADING_DATA': {
			return {
				...state,
				isLoadingData: action.payload as any
			};
		}
		case 'GENERAL_ERROR': {
			return {
				...state,
				errorState: action.payload as any
			};
		}
		case 'ALL_FILTERS_MAPPED': {
			return {
				...state,
				filterState: {
					...state.filterState,
					allFilters: action.payload as any,
					lastDeletedFilter: state.filterState?.lastDeletedFilter,
					hasMappedAllFilters: true
				}
			};
		}
		case 'STAND_ALONE_LAYOUT': {
			return {
				...state,
				standAloneLayout: action.payload as any
			};
		}
		case 'PROFILE_LAYOUT': {
			return {
				...state,
				isProfileLayout: action.payload as any
			};
		}
		case 'MODAL_CONTENT': {
			return {
				...state,
				modalContent: action.payload as any,
				isLoadingSaveUserBeacon: false
			};
		}
		case UserActions.GET_USER_PROFILE_SUCCESS: {
			return {
				...state,
				userState: {
					...state.userState,
					favoriteProducts: [...(state.userState?.favoriteProducts || [])],
					user: { ...action.payload }
				}
			};
		}

		case UserActions.GET_USER_PROFILE_BEACON_SUCCESS: {
			return {
				...state,
				userState: {
					...state.userState?.brandInfo,
					ingredientsTemplateId: action.payload?.ingredientsTemplateId,
					favoriteProducts: action.payload?.favoriteProducts,
					companyDetails: action.payload?.companyDetails,
					hasLoadProfile: true,
					user: {
						...state.userState?.user,
						company_name: action.payload?.companyDetails?.name,
						company_website: action.payload?.companyDetails?.website,
						user_type: action.payload?.companyDetails?.userType,
						job_type: action.payload?.companyDetails?.jobType,
						isBrandAdmin: action.payload?.isBrandAdmin
					}
				}
			};
		}
		case UserActions.GET_USER_ROLES_SUCCESS: {
			const isBrandAdmin = action.payload.find((role: string) => role === 'brandManager');
			return {
				...state,
				userState: {
					...state.userState,
					user: {
						...state.userState?.user,
						isBrandAdmin: isBrandAdmin ? true : false
					}
				}
			};
		}

		case UserActions.GET_USER_BRAND_PROFILE_SUCCESS: {
			const mappedProcessing = processingTechniques?.map(technique => {
				const isActive = action.payload.processingTechniques!.some(
					(elem: any) => elem.id === technique.id
				);
				return { ...technique, isActive: isActive };
			});
			const mappedOtherServicesNeeds = otherServicesNeedsArray.map(service => ({
				...service,
				isActive: action.payload.serviceProviderNeeds[service.id] || false
			}));

			const mappedRetailChannels = retailChannelsArray.map(channel => ({
				...channel,
				isActive: action.payload.currentRetailChannels[channel.id] || false
			}));
			const mappedCurrentChannels = distributorsArray.map(distributor => ({
				...distributor,
				isActive: action.payload.currentDistributionChannels[distributor.id] || false
			}));
			const mappedUNFIRegion = regionsArray.map(region => ({
				...region,
				isActive: action.payload.UNFIRegion[region.id] || false
			}));

			const mappedOtherRegion = regionsArray.map(region => ({
				...region,
				isActive: action.payload.otherDistributorRegion[region.id] || false
			}));

			const mappedKeHeRegion = regionsArray.map(region => ({
				...region,
				isActive: action.payload.keheRegion[region.id] || false
			}));
			const brandAttributes = action.payload.brandAttributes
				? action.payload.brandAttributes.split(';')
				: [];
			const brandInfoAttributes = infoAttributes.map(attribute => {
				return { id: attribute, isActive: brandAttributes.some((attr: any) => attr === attribute) };
			});

			return {
				...state,
				userState: {
					...state.userState,
					brandInfo: {
						uniqueApplicationSessionId: action.payload.uniqueApplicationSessionId,
						salesforceAccount: action.payload.salesforceAccount,
						accountPrimaryContactEmail: action.payload.accountPrimaryContactEmail
							? action.payload.accountPrimaryContactEmail
							: '',
						accountPrimaryContactName: action.payload.accountPrimaryContactName
							? action.payload.accountPrimaryContactName
							: '',
						submittedContactEmail: action.payload.submittedContactEmail || '',
						submittedContactName: `${action.payload.submittedContactFirstName || ''} ${
							action.payload.submittedContactLastName || ''
						}`,
						brandFacebook: action.payload.brandFacebook ? action.payload.brandFacebook : '',
						brandHQCity: action.payload.brandHQCity ? action.payload.brandHQCity : '',
						brandHQCountry: action.payload.brandHQCountry ? action.payload.brandHQCountry : '',
						brandHQState: action.payload.brandHQState ? action.payload.brandHQState : '',
						brandInstagram: action.payload.brandInstagram ? action.payload.brandInstagram : '',
						brandLinkedin: action.payload.brandLinkedin ? action.payload.brandLinkedin : '',
						brandLogo: action.payload.brandLogo ? action.payload.brandLogo : '',
						brandMission: action.payload.brandMission ? action.payload.brandMission : '',
						brandRepEmail: action.payload.brandRepEmail ? action.payload.brandRepEmail : '',
						brandRepName: action.payload.brandRepName ? action.payload.brandRepName : '',
						brandRepPhone: action.payload.brandRepPhone ? action.payload.brandRepPhone : '',
						brandRepTitle: action.payload.brandRepTitle ? action.payload.brandRepTitle : '',
						brandStory: action.payload.brandStory ? action.payload.brandStory : '',
						brandTiktok: action.payload.brandTiktok ? action.payload.brandTiktok : '',
						brandTwitter: action.payload.brandTwitter ? action.payload.brandTwitter : '',
						brandVideo: action.payload.brandVideo ? action.payload.brandVideo : '',
						brandWebsite: action.payload.brandWebsite ? action.payload.brandWebsite : '',
						brandYearFound: action.payload.brandYearFound ? action.payload.brandYearFound : 0,
						brandYoutube: action.payload.brandYoutube ? action.payload.brandYoutube : '',
						brokers: action.payload.brokers,
						holdingCompany: action.payload.holdingCompany ? action.payload.holdingCompany : '',
						name: action.payload.name ? action.payload.name : '',
						ownedByHoldingCompany: action.payload.ownedByHoldingCompany,
						ownedByParentCompany: action.payload.ownedByParentCompany,
						parentCompany: action.payload.parentCompany ? action.payload.parentCompany : '',
						privateLabelServices: action.payload.privateLabeServices,
						productRepEmail: action.payload.productRepEmail ? action.payload.productRepEmail : '',
						productRepName: action.payload.productRepName ? action.payload.productRepName : '',
						productRepPhone: action.payload.productRepPhone ? action.payload.productRepPhone : '',
						productRepTitle: action.payload.productRepTitle ? action.payload.productRepTitle : '',
						status: action.payload.status ? action.payload.status : '',
						otherServicesNeeds: mappedOtherServicesNeeds,
						processingTechniques: mappedProcessing,
						haveABroker: action.payload.hasBroker,
						mapPolicy: action.payload.mapPolicy,
						currentDistributionChannels: mappedCurrentChannels,
						keheRegion: mappedKeHeRegion,
						UNFIRegion: mappedUNFIRegion,
						otherDistributorRegion: mappedOtherRegion,
						currentRetailChannels: mappedRetailChannels,
						brandAttributes: brandInfoAttributes,
						activelyRaisingCapital: action.payload.activelyRaisingCapital,
						revenueLastYear: action.payload.revenueLastYear ? action.payload.revenueLastYear : 0,
						revenueExpectedThisYear: action.payload.revenueExpectedThisYear
							? action.payload.revenueExpectedThisYear
							: 0
					}
				}
			};
		}
		case UserActions.GET_USER_ONBOARD_ERROR: {
			return {
				...state,
				userState: {
					...state.userState,
					errorBob: true
				}
			};
		}
		case UserActions.UPDATE_BRAND_SUCCESS: {
			return {
				...state,
				toastState: action.payload.toastMessage
			};
		}
		case UserActions.UPDATE_PRODUCT_SUCCESS: {
			const currentProducts = state.userState?.products;
			const updatedProducts = currentProducts?.map(product =>
				product.id === action.payload.id ? action.payload : product
			);
			return {
				...state,
				toastState: action.payload.toastMessage,
				userState: {
					...state.userState,
					products: updatedProducts
				}
			};
		}
		case UserActions.UPDATE_BRAND_ERROR: {
			return {
				...state,
				toastState: action.payload
			};
		}
		case UserActions.UPDATE_PRODUCT_ERROR: {
			return {
				...state,
				toastState: action.payload
			};
		}
		case UserActions.GET_USER_PRODUCTS_PROFILE_SUCCESS: {
			return {
				...state,
				userState: {
					...state.userState,
					products: [...action.payload]
				}
			};
		}
		case UserActions.SET_INGREDIENTS_TEMPLATE_ID: {
			return {
				...state,
				userState: {
					...state.userState,
					ingredientsTemplateId: action.payload
				}
			};
		}
		case UserActions.SET_FAVORITE_PRODUCTS: {
			return {
				...state,
				isFavoriting: false,
				userState: {
					...state.userState,
					favoriteProducts: action.payload.favoriteProducts || []
				},
				toastState: action.payload.toastPayload
			};
		}
		case FilterActions.SET_SORT_BY: {
			return {
				...state,
				filterState: {
					...state.filterState,
					sortBySelected: action.payload,
					lastDeletedFilter: state.filterState?.lastDeletedFilter
				}
			};
		}
		case FilterActions.SET_OPEN_FILTER_FUNCTION_REF: {
			return {
				...state,
				filterState: {
					...state.filterState,
					openFilterFunctionRef: action.payload.openFilterFunctionRef
				}
			};
		}
		case FilterActions.SET_ON_MOUSE_LEAVE_CATEGORY_NAVBAR: {
			return {
				...state,
				filterState: {
					...state.filterState,
					onMouseLeaveCategoryNavbar: action.payload.onMouseLeaveCategoryNavbar
				}
			};
		}
		case ScreenActions.UPDATE_CURRENT_SCREEN: {
			return {
				...state,
				deviceScreen: action.payload.currentScreen
			};
		}
		case FilterActions.SET_CLOSE_FILTER_FUNCTION_REF: {
			return {
				...state,
				filterState: {
					...state.filterState,
					onFilterPanelOutsiteClick: action.payload
				}
			};
		}
		case UserActions.GET_USER_PROFILE_ERROR: {
			return {
				...state,
				authState: {
					isAuthenticated: false
				},
				userState: {
					user: undefined
				}
			};
		}
		case AuthActions.ON_MODAL_COMPANY_DETAILS_CLOSED: {
			return {
				...state,
				authState: {
					isAuthenticated: false
				}
			};
		}
		case 'COMPLETE_BRAND_DETAILS_LAYOUT': {
			return {
				...state,
				completeBrandDetailsLayout: action.payload as any
			};
		}
		case ToastActions.UPDATE_TOAST: {
			return {
				...state,
				toastState: action.payload
			};
		}
		case UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID_SUCCESS: {
			return {
				...state,
				toastState: action.payload,
				userState: {
					...state.userState,
					ingredientsTemplateId: action.payload?.ingredientsTemplateId,
					favoriteProducts: [...state.userState!.favoriteProducts!],
					hasLoadProfile: true,
					saveTemplateLoding: false,
					user: {
						...state.userState?.user
					}
				}
			};
		}
		case UserActions.UPDATE_USER_PROFILE_SUCCESS: {
			return {
				...state,
				toastState: action.payload,
				userState: {
					...state.userState,
					companyDetails: action.payload?.companyDetails,
					favoriteProducts: [...(state.userState?.favoriteProducts || [])],
					hasLoadProfile: true,
					user: {
						...action.payload?.userProfile,
						company_name: action.payload?.companyDetails?.name,
						company_website: action.payload?.companyDetails?.website,
						user_type: action.payload?.companyDetails?.userType,
						job_type: action.payload?.companyDetails?.jobType
					}
				}
			};
		}
		case UserActions.UPDATE_USER_PROFILE_ERROR: {
			return {
				...state,
				isFavoriting: false,
				toastState: action.payload
			};
		}
		case UserActions.SAVE_INGREDIENT_TEMPLATE_ERROR: {
			return {
				...state,
				toastState: action.payload,
				userState: {
					...state.userState,
					saveTemplateLoding: false
				}
			};
		}
		case UserActions.DOWLOAD_FAVORITE_PRODUCTS_ERROR: {
			return {
				...state,
				toastState: action.payload
			};
		}
		case UserActions.DOWNLOAD_FAVORITE_PRODUCTS_SUCCESS: {
			return {
				...state,
				toastState: action.payload
			};
		}
		case FilterActions.SET_IS_MOBILE_FILTER_OPEN: {
			return {
				...state,
				filterState: {
					...state.filterState,
					isMobileFilterOpen: action.payload
				}
			};
		}
		case FilterActions.SET_CARROT_ICON_CLICK: {
			return {
				...state,
				filterState: {
					...state.filterState,
					carrotIconClicked: action.payload
				}
			};
		}
		case UserActions.SET_IS_LOADING_PROFILE: {
			return {
				...state,
				userState: {
					...state.userState,
					user: { ...state.userState?.user },
					hasLoadProfile: action.payload
				}
			};
		}
		case UserActions.UPDATE_COMPANY_DETAILS_SUCCESS: {
			return {
				...state,
				hasCreatedBeaconProfile: true,
				userState: {
					...state.userState,
					companyDetails: action.payload.companyDetails,
					favoriteProducts: [...(state.userState?.favoriteProducts || [])],
					user: { ...state.userState?.user },
					hasLoadProfile: true
				},
				authState: {
					...state.authState,
					isAuthenticated: true
				}
			};
		}
		case UserActions.GET_USER_PROFILE: {
			return {
				...state,
				userState: {
					hasLoadProfile: false
				}
			};
		}
		case UserActions.SAVE_USER_BEACON_LOADING: {
			return {
				...state,
				isLoadingSaveUserBeacon: action.payload
			};
		}
		case FilterActions.SET_FAVORITE_SORT_BY: {
			return {
				...state,
				filterState: {
					...state.filterState,
					favoriteSortBySelected: action.payload,
					lastDeletedFilter: state.filterState?.lastDeletedFilter
				}
			};
		}
		case FilterActions.SET_BREADCRUMB_ELEMENTS: {
			return {
				...state,
				filterState: {
					...state.filterState,
					breadCrumbElements: action.payload
				}
			};
		}
		case AppActions.SHOW_NAVBAR: {
			return {
				...state,
				showNavbar: action.payload
			};
		}
		case AppActions.UPDATE_SHOW_BACK_TO_TOP: {
			return {
				...state,
				showBackToTop: action.payload
			};
		}
		case AppActions.LOAD_PROFILE_PROGRESS: {
			return {
				...state,
				loadProfileProgress: action.payload
			};
		}
		case UserActions.UPDATE_USER_FAVORITE_PRODUCTS: {
			return {
				...state,
				isFavoriting: true
			};
		}
		case AppActions.SET_CREATE_BEACON_PROFILE_PROGRESS: {
			return {
				...state,
				createBeaconProfileProgress: action.payload
			};
		}
		case AppActions.IS_COMPANY_LOADING: {
			return {
				...state,
				isCompanyLoading: action.payload
			};
		}
		case AppActions.UNAUTHORIZED_ERROR_RESOLVE: {
			return {
				...state,
				authState: {
					isAuthenticated: false
				},
				userState: undefined
			};
		}
		case UserActions.CREATE_BEACON_PROFILE_ERROR: {
			return {
				...state,
				toastState: action.payload,
				isLoadingSaveUserBeacon: false,
				authState: {
					isAuthenticated: false
				},
				userState: undefined
			};
		}
		case AppActions.IS_ON_FAVORITES_PAGE: {
			return {
				...state,
				isOnFavoritesPage: action.payload
			};
		}
		case FilterActions.SET_BUBBLE_FUNCTION_REF: {
			return {
				...state,
				filterState: {
					...state.filterState,
					bubbleFunctionRef: [...(state.filterState?.bubbleFunctionRef || []), action.payload]
				}
			};
		}
		case FilterActions.CLEAR_BUBBLE_FUNCTION_REF: {
			return {
				...state,
				filterState: {
					...state.filterState,
					bubbleFunctionRef: []
				}
			};
		}
		case FilterActions.CURRENT_FILTER_OPEN_INDEX: {
			return {
				...state,
				filterState: {
					...state.filterState,
					currentFilterOpenIndex: action.payload
				}
			};
		}
		case SearchActions.HAS_CLICKED_OUTSIDE_SEARCH: {
			return {
				...state,
				searchState: {
					...state.searchState,
					hasClickedOutsideBar: action.payload
				}
			};
		}
		case SearchActions.SEARCH_SUGGESTIONS_SUCCESS: {
			return {
				...state,
				searchState: {
					...state.searchState,
					suggestions: action.payload
				}
			};
		}
		case SearchActions.SEARCH_SUGGESTIONS_FAILURE: {
			return {
				...state,
				searchState: {
					...state.searchState,
					suggestionError: true,
					suggestions: []
				}
			};
		}
		case SearchActions.CLEAR_SEARCH_SUGGESTIONS: {
			return {
				...state,
				searchState: {
					...state.searchState,
					suggestions: action.payload
				}
			};
		}
		case SearchActions.SET_SEARCH_TERM_SUCCESS: {
			return {
				...state,
				searchState: {
					...state.searchState,
					searchTerm: action.payload.searchTerm,
					suggestions: []
				},
				filterState: {
					...state.filterState,
					sortByOptions: action.payload.sortByOptions,
					sortBySelected: action.payload.sortBySelected
				}
			};
		}
		case FilterActions.SET_SELECTED_FILTERS: {
			return {
				...state,
				filterState: {
					...state.filterState,
					selectedFilters: action.payload.filters,
					selectedCategory: action.payload.selectedCategory
				}
			};
		}
		case FilterActions.MAP_BROWSER_URL: {
			return {
				...state,
				filterState: {
					...state.filterState,
					lastFilterUrl: action.payload
				}
			};
		}
		case IngredientFilterActions.SET_INGREDIENT_FILTER_OPTIONS: {
			return {
				...state,
				ingredientFilterState: {
					...state.ingredientFilterState,
					filterOptions: action.payload
				}
			};
		}
		case IngredientFilterActions.SET_INGREDIENT_FILTER: {
			return {
				...state,
				ingredientFilterState: {
					...state.ingredientFilterState,
					selectedFilter: action.payload
				}
			};
		}
		case FilterActions.SET_CLEAR_ALL_FILTERS_AND_SEARCH_TERM: {
			return {
				...state,
				filterState: {
					...state.filterState,
					selectedFilters: [],
					selectedCategory: undefined,
					lastDeletedFilter: undefined,
					sortBySelected: action.payload.sortBy,
					sortByOptions: action.payload.sortByOptions
				},
				searchState: {
					...state.searchState,
					searchTerm: undefined,
					suggestions: []
				}
			};
		}
		case UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID: {
			return {
				...state,
				userState: {
					...state.userState,
					saveTemplateLoding: true
				}
			};
		}
		case FilterActions.MAP_NAVBAR_ELEMENTS_SUCCESS: {
			return {
				...state,
				filterState: {
					...state.filterState,
					categoryNavbarElements: action.payload
				}
			};
		}
		case FilterActions.MAP_NAVBAR_ELEMENTS_FAILURE: {
			return {
				...state,
				filterState: {
					...state.filterState,
					categoryNavbarElements: action.payload
				}
			};
		}
		case UserActions.GET_ANONYMOUS_USER_TOKEN: {
			return {
				...state,
				userState: {
					...state.userState,
					hasLoadAnonymous: false
				}
			};
		}
		case UserActions.GET_ANONYMOUS_USER_TOKEN_SUCCESS: {
			return {
				...state,
				userState: {
					...state.userState,
					isAnonymous: true,
					hasLoadAnonymous: true
				}
			};
		}
		case UserActions.GET_ANONYMOUS_USER_TOKEN_ERROR: {
			return {
				...state,
				userState: {
					...state.userState,
					isAnonymous: false,
					hasLoadAnonymous: false
				}
			};
		}
		case UserActions.SET_UNAUTHORIZE_ANONYMOUS_USER: {
			return {
				...state,
				userState: {
					...state.userState,
					hasAnonymousEnded: true
				}
			};
		}
		case UserActions.CURRENT_SESSION_DETECTED: {
			return {
				...state,
				toastState: action.payload
			};
		}
		case UserActions.CLICK_ON_FILTER: {
			return {
				...state,
				userState: {
					...state.userState,
					hasClickOnFilter: action.payload
				}
			};
		}
		case UserActions.REGISTER_USER_CLICK_EVENT_ERROR: {
			return {
				...state,
				userState: {
					...state.userState,
					hasAnonymousEnded: true
				}
			};
		}
		case UserActions.UPDATE_USER_BEACON_INFO_STATUS: {
			return {
				...state,
				userBeaconInfo: action.payload
			};
		}

		case UserActions.UPDATE_USER_SUBSCRIBE_STATUS: {
			return {
				...state,
				userState: {
					...state.userState,
					subscribeAlertsStatus: action.payload
				}
			};
		}

		case UserActions.USER_SUBSCRIBE_STATUS_SUCCESS: {
			return {
				...state,
				toastState: action.payload
			};
		}

		case UserActions.USER_SUBSCRIBE_STATUS_ERROR: {
			return {
				...state,
				toastState: action.payload
			};
		}

		case AppActions.SET_IS_ON_HOME_PAGE: {
			return {
				...state,
				homePageLayout: action.payload
			};
		}
		case AppActions.SET_CONTACT_ACCORDION_OPEN: {
			return {
				...state,
				contactAccordionOpened: action.payload
			};
		}
		case AppActions.UPDATE_PROFILE_STATE: {
			return {
				...state,
				profileState: action.payload
			};
		}
		case EngagementDashboardActions.START_FETCHING_ENGAGEMENT_DATA: {
			return {
				...state,
				dashboardState: {
					...state.dashboardState,
					isLoading: true,
					hasError: false
				}
			};
		}
		case EngagementDashboardActions.FETCH_ENGAGEMENT_DATA_SUCCESS: {
			return {
				...state,
				dashboardState: {
					...state.dashboardState,
					data: action.payload,
					isLoading: false,
					hasError: false
				}
			};
		}
		case EngagementDashboardActions.FETCH_ENGAGEMENT_DATA_FAILURE: {
			return {
				...state,
				dashboardState: {
					...state.dashboardState,
					isLoading: false,
					hasError: true
				}
			};
		}
		case AuthActions.IS_REFRESHING_TOKEN: {
			return {
				...state,
				authState: {
					...state.authState,
					isAuthenticated: isAuthenticated(),
					isRefreshingToken: action.payload
				}
			};
		}
		default:
			return state;
	}
};
