import {
	Attribute,
	Base,
	Brand,
	Distributor,
	Ingredient,
	Product,
	Region,
	Taxonomy,
	ToolTip
} from './model';
import { ICheckBoxState } from '../components/UI/CheckboxComponent/model';
import { ToastPosition } from 'react-toastify';
import { UserType } from './model';

export interface BaseVM extends Base {}

export interface BrandVM extends Brand {
	attributes: IBrandAttributes[];
	hasContactInformation?: boolean;
	hasBrokerContactInformation?: boolean;
	certifications?: string[];
	offerings?: any[];
	clients?: string[];
	testimonials?: any[];
}

export interface ProductVM extends Product {
	filtered?: boolean;
	hasNavigate?: boolean;
	allImagesHaveError?: boolean;
}

export interface DistributorVM extends Distributor {}
export interface RegionVM extends Region {}

export interface TaxonomyVM extends Taxonomy {}

export interface IngredientVM extends Ingredient {
	color?: string;
	category?: IngredientCategory;
	usages?: IUsage[];
}

export interface AttributeVM extends Attribute {}

export interface PintoFilters {
	id?: string;
	name?: string;
	filters: FilterVM[];
	type: FilterType;
}
export enum FilterLevel {
	FIRST,
	SECOND,
	THIRD,
	FOURTH
}
export enum FilterType {
	CATEGORY,
	DISTRIBUTOR,
	REGION,
	ATTRIBUTES,
	CERTIFICATION,
	ALLERGENS,
	INGREDIENTS,
	CONSUMER_DIETS,
	ANIMAL_WELFARE,
	BUSINESS_PRACTICES,
	CLIMATE,
	PACKAGING,
	BRAND,
	WHATS_NEW,
	NEW_TO_MARKET,
	NEW_TO_BEACON,
	NEXTY_FINALISTS
}
export interface Filter {
	id?: string;
	name?: string;
	isChecked: boolean;
	children: any;
	toolTip?: ToolTip;
}
export interface FilterVM extends Filter {
	level?: FilterLevel;
	index?: number;
	type?: FilterType;
	state?: ICheckBoxState;
	setState?: Function;
	parentLength?: number;
	parentId?: string;
	mainMenuId?: string;
	checkBoxGroupId?: string;
	categoryName?: string;
	carrotIconRef?: string;
	isSuperCategory?: boolean;
	isSuperLevelFilter?: boolean;
	breadcrumText?: string;
	checkBoxDOMRef?: string;
	isExternalFilter?: boolean;
	isLastFilterByParent?: boolean;
	hasBasedLevelFilters?: boolean;
	parentFilterId?: string;
	breadCrumbElements?: any[];
}
export enum GenericFilterKey {
	Ingredients = 'Ingredients',
	Brand = 'Brand',
	Attributes = 'Attributes',
	Regions = 'Regions',
	Distributor = 'Distributor',
	Taxonomy = 'Taxonomy',
	Allergens = 'Allergens',
	Certifications = 'Certifications',
	ConsumerDiet = 'ConsumerDiet',
	Packaging = 'Packaging',
	Climate = 'Climate',
	AnimalWelfare = 'AnimalWelfare',
	BussinessPractices = 'BussinessPractices'
}

export interface ProductPagination {
	offset: number;
	limit: number;
}
export interface FilterQueryVM {
	key: GenericFilterKey;
	ids: string[];
}
export interface IngredientsTemplate extends Base {
	purpose: Purporse[];
	color?: 'blue' | 'green' | 'yellow';
	isCurrent?: boolean;
	ingredients?: IngredientVM[];
}
export interface Purporse extends Base {
	ingredients: IngredientVM[];
}

export interface LineTableComponent {
	categories?: IngredientCategory[];
	usages?: IUsage[];
	displayName: string;
}
export enum genericFilterType {
	allCategoriesId = 'allCategories'
}
export enum viewModeType {
	DESKTOP,
	MOBILE
}
export enum sortOptionType {
	ALPHABETICAL,
	FAVORITES,
	SCORE,
	FAVORITESDATE,
	CURRENT_EVENT,
	MOST_RELEVANT
}
interface ProductSort {
	attribute?: string;
	order: string[];
}
export interface ISortByOption {
	id: string;
	name?: string;
	sortType: sortOptionType;
	productSort: ProductSort;
}
export enum ScreenType {
	SMALL_DESKTOP,
	MEDIUM_DESKTOP,
	LARGE_DESKTOP,
	TABLET,
	MOBILE
}
export interface IModalContent {
	content: any;
	showCloseIcon: boolean;
	canScroll: boolean;
	isCompanyDetailsModal?: boolean;
	closeOnDocumentClick: boolean;
}

export interface ITrackingEvent {
	transactionId: string;
	entityId: string;
	cookieId?: string;
	formId?: string;
	status: TrackingEventStatus;
	type: TrackingEventType | string;
	timeStamp: string;
	eventPayload: ITrackingEventPayload;
}

export enum TrackingEventStatus {
	SUCCESS = 'success'
}
export enum TrackingEventType {
	FAVORITES = 'ADD_TO_FAVORITES',
	REQUEST_SAMPLE = 'REQUEST_SAMPLE',
	CONTACT_BRAND = 'CONTACT_BRAND',
	HOW_GOOD = 'HOW_GOOD',
	MARKET_READY = 'MARKET_READY',
	COMPANY_DETAILS = 'COMPANY_DETAILS',
	CLICK_ON_SEE_US_EXPO_BUTTON = 'CLICK_ON_SEE_US_EXPO_BUTTON',
	CLICK_ON_EXPO_MAP = 'CLICK_ON_EXPO_MAP',
	SEARCH_TERM = 'SEARCH_TERM',
	PUBLIC_USER_CONVERTED = 'PUBLIC_USER_CONVERTED',
	PRODUCTS_APPEAR_ON_FILTERED = 'PRODUCTS_APPEAR_ON_FILTERED',
	EMAIL_TRACKING_PRODUCT = 'Click on EMAIL_TRACKING_PRODUCT',
	EMAIL_TRACKING_DISCOVERY = 'Click on EMAIL_TRACKING_DISCOVERY',
	EMAIL_TRACKING_LANDING = 'Click on EMAIL_TRACKING_LANDING',
	BOB_TRACKING_MENU_BRAND = 'Click on BRAND_MENU',
	BOB_TRACKING_BRAND_SAVE = 'Click on SAVE_BRAND',
	BOB_TRACKING_GOB_CARD = 'Click on GOB_CARD',
	BOB_TRACKING_GOB_ACCORDION = 'Click on GOB_ACCORDION',
	BOB_TRACKING_BEACON_HUB = 'Click on BEACON_HUB'
}
export interface ITrackingEventPayload {
	id: string;
	name: string;
	type: string;
	url: string;
	tags: string[];
	metadata: any;
}
export interface IBrandAttributes {
	name: string;
	icon: string;
	content?: string;
	isLinkContent: boolean;
	linkText?: string;
}
export interface IToastState {
	message: string;
	title: string;
	position: ToastPosition;
	type: 'success' | 'error' | 'warning' | 'info';
	closeOnClick: boolean;
	autoClose: number;
	afterAction?: () => void;
	undoAction?: () => void;
	afterActionText?: string;
	undoActionText?: string;
}
export interface IRoleType {
	id: string;
	name: string;
	accessType: UserType;
	showInProfile: boolean;
}
export interface JobTypeMap {
	[key: string]: string[];
}
export interface ISuggestion {
	id?: string;
	name: string;
	type: string;
}
export type EmptyProductsError = 'search' | 'filter';

export interface IFiltersUrlParameters {
	[key: string]: string[];
}
export interface IngredientCategory {
	id: string;
	name: string;
}
export interface IngredientByPorpurse extends Base {
	categories?: IngredientCategory[];
	usages?: IUsage[];
	displayName?: string;
}
export interface IUsage extends Base {}
export interface ICategoryNavbarFilter extends Base {
	linkUrl?: string;
	description?: string;
	imagePath?: any;
	filters?: FilterVM[];
	panelFilterId?: string;
}

export interface ICategoryNavbar extends Base {
	elements: ICategoryNavbarFilter[];
}
export enum NavbarElementType {
	EVENT = 'event',
	NEW = 'new',
	BUSINESS_SERVICE = 'business'
}
export enum NavbarSubElementType {
	MAP_EVENT = 'event-map',
	WOMEN_OWNED = 'women-owned',
	REGENERATIVE = 'certified-regenerative',
	HUB = 'hub',
	AGENDA = 'agenda',
	EXHIBITOR_LIST = 'exhibitor-list',
	NEW_TO_MARKET = 'new-to-market',
	NEW_TO_BEACON = 'new-to-beacon',
	NEXTY_FINALISTS = 'nexty-finalists'
}
export interface EngagementDashboard {
	brandId: string;
	data: EngagementDashboardData;
}

export interface EngagementDashboardData {
	kpi: Kpi[];
	tables: Table[];
	charts: Chart[];
}

export interface Chart {
	title: string;
	legend: string;
	metric: string;
	data: ChartData;
}

export interface ChartData {
	labels: string[];
	values: number[];
}

export interface Kpi {
	title: string;
	value: string;
	metric: string;
}

export interface Table {
	title: string;
	metric: string;
	data: Datum[];
}

export interface Datum {
	productName: string;
	value: string;
}

export interface IHighlightedProducts {
	products: Product[];
}
export interface IAppFilter {
	distributors: DistributorVM[];
	regions: RegionVM[];
	taxonomyFilters: FilterVM[];
	climates: AttributeVM[];
	packagings: AttributeVM[];
	certifications: AttributeVM[];
	claimingIngredients: AttributeVM[];
	animalWalfares: AttributeVM[];
	businessPractices: AttributeVM[];
	consumerDiets: AttributeVM[];
	allergens: AttributeVM[];
	topAttributes: AttributeVM[];
}
export interface IBrandOnboardContent {
	id: string;
	title: string;
	description: string;
	active: boolean;
	content: any;
}

export enum TrackingEmailVariables {
	UTM_TRACKING = 'utm_tracking'
}
