import { BrandVM } from '../../common/view-model';

const DEAFAULT_STATE: IBrandProfileState = {
	backToPageText: 'Back',
	missionStatementTitleText: 'Mission Statement',
	ourStoryTitleText: 'Our Story',
	contactTitleText: 'Contact',
	brandContactCardTitle: 'Brand Contact',
	brokerContactCardTitle: 'Broker Contact',
	contactBrandTitle: 'Contact Information'
};

interface IBrandProfileState {
	backToPageText: string;
	missionStatementTitleText: string;
	ourStoryTitleText: string;
	contactTitleText: string;
	brandContactCardTitle: string;
	brokerContactCardTitle: string;
	contactBrandTitle: string;
}
export const aboutBrandState = {
	...DEAFAULT_STATE
};
const validateNullability = (value: string | null | undefined): boolean => {
	return value !== null && value !== undefined && value !== '';
};
export const mapBrandAttributes = (brand: BrandVM): BrandVM => {
	return {
		...brand,
		hasContactInformation:
			(validateNullability(brand.contact_email) && validateNullability(brand.contact_phone)) ||
			validateNullability(brand.contact_name),
		hasBrokerContactInformation: brand.brokers && brand.brokers.length > 0,
		attributes: [
			{
				name: 'Headquarters',
				icon: 'ri-map-pin-line',
				content: `${brand.hq_city}${brand.hq_state ? ' , ' : ' '}${brand.hq_state}`,
				isLinkContent: false
			},
			{
				name: 'Founded Date',
				icon: 'ri-calendar-2-line',
				content: brand.brand_foundation_year,
				isLinkContent: false
			},
			{
				name: 'Distributors',
				icon: 'ri-truck-line',
				content: brand.distributors,
				isLinkContent: false
			},
			{
				name: 'Holding Company',
				icon: 'ri-organization-chart',
				content: brand.holding_company || 'None',
				isLinkContent: false
			},
			{
				name: 'Parent Company',
				icon: 'ri-parent-line',
				content: brand.parent_company || 'None',
				isLinkContent: false
			},
			{
				name: 'Brand Website',
				icon: 'ri-global-line',
				content: brand.brand_website,
				isLinkContent: true,
				linkText: 'Visit Site'
			},
			{
				name: 'Brand Video',
				icon: 'ri-live-line',
				content: brand.brand_video,
				isLinkContent: true,
				linkText: 'Watch Video'
			},
			{
				name: 'Youtube',
				icon: 'ri-youtube-line',
				content: brand.brand_youtube,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Instagram',
				icon: 'ri-instagram-line',
				content: brand.brand_instagram,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'TikTok',
				icon: 'ri-tiktok',
				content: brand.brand_tiktok,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Twitter',
				icon: 'ri-twitter-line',
				content: brand.brand_twitter,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Facebook',
				icon: 'ri-facebook-circle-line',
				content: brand.brand_facebook,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'LinkedIn',
				icon: 'ri-linkedin-fill',
				content: brand.brand_linkedin,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Private Label Available',
				icon: 'ri-price-tag-3-line',
				content: brand.uses_private_label ? 'Yes' : 'No',
				isLinkContent: false
			}
		]
	};
};

// TO-DO temporal mapping for brand provider still needs to configure the correct keys for the BE endpoint

export const mapBrandProviderAttributes = (brand: BrandVM): BrandVM => {
	return {
		...brand,

		demo_link: 'https://www.google.com',
		sales_sheets: 'https://www.youtube.com',
		brand_mission: '',
		hasContactInformation:
			(validateNullability(brand.contact_email) && validateNullability(brand.contact_phone)) ||
			validateNullability(brand.contact_name),
		hasBrokerContactInformation: brand.brokers && brand.brokers.length > 0,
		attributes: [
			{
				name: 'Headquarters',
				icon: 'ri-map-pin-line',
				content: `${brand.hq_city}${brand.hq_state ? ' , ' : ' '}${brand.hq_state}`,
				isLinkContent: false
			},
			{
				name: 'Founded Date',
				icon: 'ri-calendar-2-line',
				content: brand.brand_foundation_year,
				isLinkContent: false
			},
			{
				name: 'Distributors',
				icon: 'ri-truck-line',
				content: brand.distributors,
				isLinkContent: false
			},
			{
				name: 'Holding Company',
				icon: 'ri-organization-chart',
				content: brand.holding_company || 'None',
				isLinkContent: false
			},
			{
				name: 'Parent Company',
				icon: 'ri-parent-line',
				content: brand.parent_company || 'None',
				isLinkContent: false
			},
			{
				name: 'Brand Website',
				icon: 'ri-global-line',
				content: brand.brand_website,
				isLinkContent: true,
				linkText: 'Visit Site'
			},
			{
				name: 'Brand Video',
				icon: 'ri-live-line',
				content: brand.brand_video,
				isLinkContent: true,
				linkText: 'Watch Video'
			},
			{
				name: 'Youtube',
				icon: 'ri-youtube-line',
				content: brand.brand_youtube,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Instagram',
				icon: 'ri-instagram-line',
				content: brand.brand_instagram,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'TikTok',
				icon: 'ri-tiktok',
				content: brand.brand_tiktok,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Twitter',
				icon: 'ri-twitter-line',
				content: brand.brand_twitter,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Facebook',
				icon: 'ri-facebook-circle-line',
				content: brand.brand_facebook,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'LinkedIn',
				icon: 'ri-linkedin-fill',
				content: brand.brand_linkedin,
				isLinkContent: true,
				linkText: 'View Profile'
			},
			{
				name: 'Private Label Available',
				icon: 'ri-price-tag-3-line',
				content: brand.uses_private_label ? 'Yes' : 'No',
				isLinkContent: false
			}
		],
		certifications: [
			'Certified Organic',
			'Certified Non-GMO',
			'Certified Gluten Free',
			'Certified Regenerative'
		],
		offerings: [
			{
				category: 'Consultants',
				offers: [
					'Marketing Strategy',
					'Market Research',
					'Product Research',
					'Customer Research',
					'Product Development'
				]
			},
			{
				category: 'Labs / Testing',
				offers: [
					'Marketing Strategy',
					'Market Research',
					'Product Research',
					'Customer Research',
					'Product Development'
				]
			},
			{
				category: 'Legal / Regulatory',
				offers: [
					'Marketing Strategy',
					'Market Research',
					'Product Research',
					'Customer Research',
					'Product Development'
				]
			},
			{
				category: 'Research',
				offers: [
					'Marketing Strategy',
					'Market Research',
					'Product Research',
					'Customer Research',
					'Product Development'
				]
			},
			{
				category: 'Marketing',
				offers: [
					'Marketing Strategy',
					'Market Research',
					'Product Research',
					'Customer Research',
					'Product Development'
				]
			}
		],
		clients: [
			'https://savvygreen.net/wp-content/uploads/2021/12/logo_0_0.png',
			'https://static.wixstatic.com/media/fcfac9_04d148c1583c474e844d2de3eb5cdead~mv2.png/v1/fill/w_194,h_216,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/fcfac9_04d148c1583c474e844d2de3eb5cdead~mv2.png',
			'https://cdn11.bigcommerce.com/s-lqr03nfajt/images/stencil/original/rfb-1_1623769677__43656.original.png',
			'https://joyfulbathco.com/cdn/shop/files/Logo-with-Text_1200x565_03899c0c-7377-41dd-b657-ebe7f1414c9c.jpg',
			'https://savvygreen.net/wp-content/uploads/2021/12/logo_0_0.png'
		],
		testimonials: [
			{
				author: 'Name',
				description:
					'Partnering with Natural Brand Works has been a game-changer for Savvy Green. Their deep understanding of the natural products industry, combined with strategic insights, helped us refine our brand message and expand our reach. Thanks to their tailored approach, we’ve seen improved market positioning and stronger customer engagement. They truly understand the needs of brands like ours, and their expertise has been instrumental in helping us achieve our growth goals.',
				company: 'Company'
			},
			{
				author: 'Name',
				description:
					'Partnering with Natural Brand Works has been a game-changer for Savvy Green. Their deep understanding of the natural products industry, combined with strategic insights, helped us refine our brand message and expand our reach. Thanks to their tailored approach, we’ve seen improved market positioning and stronger customer engagement. They truly understand the needs of brands like ours, and their expertise has been instrumental in helping us achieve our growth goals.',
				company: 'Company'
			},
			{
				author: 'Name',
				description:
					'Partnering with Natural Brand Works has been a game-changer for Savvy Green. Their deep understanding of the natural products industry, combined with strategic insights, helped us refine our brand message and expand our reach. Thanks to their tailored approach, we’ve seen improved market positioning and stronger customer engagement. They truly understand the needs of brands like ours, and their expertise has been instrumental in helping us achieve our growth goals.',
				company: 'Company'
			},
			{
				author: 'Name',
				description:
					'Partnering with Natural Brand Works has been a game-changer for Savvy Green. Their deep understanding of the natural products industry, combined with strategic insights, helped us refine our brand message and expand our reach. Thanks to their tailored approach, we’ve seen improved market positioning and stronger customer engagement. They truly understand the needs of brands like ours, and their expertise has been instrumental in helping us achieve our growth goals.',
				company: 'Company'
			}
		]
	};
};
