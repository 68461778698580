import {
	Filter,
	ISortByOption,
	IFiltersUrlParameters,
	ICategoryNavbar
} from '../common/view-model';
import { ISelectedFilterState } from '../components/filter/generic-filter/model';
import { AppState } from './AppReducer';
import { Action } from 'redux-actions';
import { categoryNavbarElements } from '../mock/categoryNavbarElements';

export class FilterActions {
	static readonly ADD_FILTER = '@@filter/ADD_FILTER';
	static readonly REMOVE_FILTER = '@@filter/REMOVE_FILTER';
	static readonly SET_CATEGORY = '@@filter/SET_CATEGORY';
	static readonly CLEAR_ALL_FILTERS = '@@filter/CLEAR_ALL_FILTERS';
	static readonly FILTER_STATE = '@@filter/FILTER_STATE';
	static readonly BUSINESS_FILTER_STATE = '@@filter/BUSINESS_FILTER_STATE';
	static readonly ADD_PARENT_FILTER = '@@filter/ADD_PARENT_FILTER';
	static readonly REMOVE_PARENT_FILTER = '@@filter/REMOVE_PARENT_FILTER';
	static readonly CLEAR_ALL_CATEGORIES = '@@filter/CLEAR_ALL_CATEGORIES';
	static readonly MAP_FILTERS_TO_QUERY = '@@filter/MAP_FILTERS_TO_QUERY';
	static readonly SET_SORT_BY = '@@filter/SET_SORT_BY';
	static readonly ADD_EXTERNAL_FILTER = '@@filter/ADD_EXTERNAL_FILTER';
	static readonly ADD_OPEN_FILTER_FUNCTION_REF = '@@filter/ADD_OPEN_FILTER_FUNCTION_REF';
	static readonly SET_OPEN_FILTER_FUNCTION_REF = '@@filter/REMOVE_OPEN_FILTER_FUNCTION_REF';
	static readonly ADD_ON_MOUSE_LEAVE_CATEGORY_NAVBAR = '@@filter/ADD_ON_MOUSE_LEAVE';
	static readonly SET_ON_MOUSE_LEAVE_CATEGORY_NAVBAR = '@@filter/SET_ON_MOUSE_LEAVE';
	static readonly SET_CLOSE_FILTER_FUNCTION_REF = '@@filter/SET_CLOSE_FILTER_FUNCTION_REF';
	static readonly ADD_CLOSE_FILTER_FUNCTION_REF = '@@filter/ADD_CLOSE_FILTER_FUNCTION_REF';
	static readonly REMOVE_ALL_AND_ADD_FILTER = '@@filter/REMOVE_ALL_AND_ADD_FILTER';
	static readonly REMOVE_ALL_AND_LEAVE_ONE_FILTER = '@@filter/REMOVE_ALL_AND_LEAVE_ONE_FILTER';
	static readonly SET_IS_MOBILE_FILTER_OPEN = '@@filter/SET_IS_MOBILE_FILTER_OPEN';
	static readonly SET_CARROT_ICON_CLICK = '@@filter/SET_CARROT_ICON_CLICK';
	static readonly SET_FAVORITE_SORT_BY = '@@filter/SET_FAVORITE_SORT_BY';
	static readonly SET_BREADCRUMB_ELEMENTS = '@@filter/SET_BREADCRUMB_ELEMENTS';
	static readonly SET_BUBBLE_FUNCTION_REF = '@@filter/SET_BUBBLE_FUNCTION_REF';
	static readonly CLEAR_BUBBLE_FUNCTION_REF = '@@filter/CLEAR_BUBBLE_FUNCTION_REF';
	static readonly CURRENT_FILTER_OPEN_INDEX = '@@filter/CURRENT_FILTER_OPEN_INDEX';
	static readonly SET_SELECTED_FILTERS = '@@filter/SET_SELECTED_FILTERS';
	static readonly MAP_FILTERS_FROM_URL = '@@filter/MAP_FILTERS_FROM_URL';
	static readonly MAP_BROWSER_URL = '@@filter/MAP_BROWSER_URL';
	static readonly CLEAR_ALL_FILTERS_AND_SEARCH_TERM = '@@filter/CLEAR_ALL_FILTERS_AND_SEARCH_TERM';
	static readonly SET_CLEAR_ALL_FILTERS_AND_SEARCH_TERM =
		'@@filter/SET_CLEAR_ALL_FILTERS_AND_SEARCH_TERM';
	static readonly MAP_NAVBAR_ELEMENTS = '@@filter/MAP_NAVBAR_ELEMENTS';
	static readonly MAP_NAVBAR_ELEMENTS_SUCCESS = '@@filter/MAP_NAVBAR_ELEMENTS_SUCCESS';
	static readonly MAP_NAVBAR_ELEMENTS_FAILURE = '@@filter/MAP_NAVBAR_ELEMENTS_FAILURE';
	static readonly ADD_BUSINESS__FILTER = '@@filter/ADD_BUSINESS__FILTER';
	static addFilter(filter: Filter): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.ADD_FILTER,
			payload: {
				filter
			}
		});
	}
	static removeFilter(filter: Filter, clearCategory: boolean): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.REMOVE_FILTER,
			payload: {
				filter,
				clearCategory
			}
		});
	}
	static setCategory(category: string | undefined): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.SET_CATEGORY,
			payload: category
		});
	}
	static clearAllFilters(): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.CLEAR_ALL_FILTERS
		});
	}
	static addFilterState(state: ISelectedFilterState): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.FILTER_STATE,
			payload: {
				state
			}
		});
	}
	static addParentFilter(filter: Filter): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.ADD_PARENT_FILTER,
			payload: {
				filter
			}
		});
	}
	static removeParentFilter(filter: Filter): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.REMOVE_PARENT_FILTER,
			payload: {
				filter
			}
		});
	}
	static clearAllCategories(): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.CLEAR_ALL_CATEGORIES
		});
	}
	static mapQueryToFilters(): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.MAP_FILTERS_TO_QUERY
		});
	}
	static setSortBy(sortBy: ISortByOption | undefined): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.SET_SORT_BY,
			payload: sortBy
		});
	}
	static addExternalfilter(filter: Filter): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.ADD_EXTERNAL_FILTER,
			payload: {
				filter
			}
		});
	}
	static addOpenFilterFunctionRef(openFilterFunctionRef: () => void): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.ADD_OPEN_FILTER_FUNCTION_REF,
			payload: {
				openFilterFunctionRef
			}
		});
	}
	static setOpenFilterFunctionRef(payload: any): Action<AppState> {
		return {
			type: FilterActions.SET_OPEN_FILTER_FUNCTION_REF,
			payload
		};
	}
	static addOnMouseLeaveCategoryNavbar(
		onMouseLeaveCategoryNavbar: () => void
	): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.ADD_ON_MOUSE_LEAVE_CATEGORY_NAVBAR,
			payload: {
				onMouseLeaveCategoryNavbar
			}
		});
	}
	static setOnMouseLeaveCategoryNavbar(payload: any): Action<AppState> {
		return {
			type: FilterActions.SET_ON_MOUSE_LEAVE_CATEGORY_NAVBAR,
			payload
		};
	}
	static addCloseFilterFunctionRef(closeFilterFunctionRef: Function): Action<any> {
		return {
			type: FilterActions.ADD_CLOSE_FILTER_FUNCTION_REF,
			payload: closeFilterFunctionRef
		};
	}
	static setCloseFilterFunctionRef(payload: () => void): Action<any> {
		return {
			type: FilterActions.SET_CLOSE_FILTER_FUNCTION_REF,
			payload
		};
	}
	static removeAllAndAddFilter(filter: Filter): Action<any> {
		return {
			type: FilterActions.REMOVE_ALL_AND_ADD_FILTER,
			payload: filter
		};
	}
	static removeAllAndLeaveOneFilter(filter: Filter): Action<any> {
		return {
			type: FilterActions.REMOVE_ALL_AND_LEAVE_ONE_FILTER,
			payload: filter
		};
	}
	static setIsMobileFilterOpen(isMobileFilterOpen: boolean): Action<any> {
		return {
			type: FilterActions.SET_IS_MOBILE_FILTER_OPEN,
			payload: isMobileFilterOpen
		};
	}
	static setCarrotIconClick(carrotIconClick: boolean): Action<any> {
		return {
			type: FilterActions.SET_CARROT_ICON_CLICK,
			payload: carrotIconClick
		};
	}
	static setFavoriteSortBy(sortBy: ISortByOption | undefined): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.SET_FAVORITE_SORT_BY,
			payload: sortBy
		});
	}
	static setBreadcrumbElements(breadcrumbElements?: string[]): FilterAction<AppState> {
		return Object.assign({
			type: FilterActions.SET_BREADCRUMB_ELEMENTS,
			payload: breadcrumbElements
		});
	}
	static setBubbleFunctionRef(bubbleFunctionRef: any): Action<any> {
		return {
			type: FilterActions.SET_BUBBLE_FUNCTION_REF,
			payload: bubbleFunctionRef
		};
	}
	static clearBubbleFunctionRef(): Action<any> {
		return {
			type: FilterActions.CLEAR_BUBBLE_FUNCTION_REF,
			payload: undefined
		};
	}
	static setCurrentFilterOpenIndex(currentFilterOpenIndex: number | undefined): Action<any> {
		return {
			type: FilterActions.CURRENT_FILTER_OPEN_INDEX,
			payload: currentFilterOpenIndex
		};
	}
	static setSelectedFiltersFromUrl(
		selectedFilters: IFiltersUrlParameters
	): Action<IFiltersUrlParameters> {
		return {
			type: FilterActions.MAP_FILTERS_FROM_URL,
			payload: selectedFilters
		};
	}
	static clearAllFiltersAndSearchTerm(): Action<any> {
		return {
			type: FilterActions.CLEAR_ALL_FILTERS_AND_SEARCH_TERM,
			payload: undefined
		};
	}
	static mapNavbarElements(): Action<ICategoryNavbar[]> {
		return {
			type: FilterActions.MAP_NAVBAR_ELEMENTS,
			payload: categoryNavbarElements
		};
	}
	static addBusinessFilter(businessFilter: any): Action<any> {
		return {
			type: FilterActions.ADD_BUSINESS__FILTER,
			payload: businessFilter
		};
	}
}
export interface FilterAction<T> extends Action<T> {
	filter: Filter;
}
