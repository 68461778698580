import './DistributionFormComponent.scss';
import { useEffect, useState } from 'react';
import { BrandInfo, IProfileState } from '../../../../redux/AppReducer';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import { DEFAULT_LABELS } from './model';
import LoadingBobComponent from '../../GenericBaseForms/LoadingBobComponent/LoadingBobComponent';
interface IDistributionForm {
	brandState: BrandInfo;
	onUpdateProfileBrand: (brandState: BrandInfo) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
	onChange: (data: any) => void;
	onValidationChange: (isValid: boolean) => void;
	cleanForm: boolean;
	updateKeyFc: () => void;
	profileState: IProfileState;
}
const DistributionSchema = Yup.object().shape({
	mapPolicy: Yup.boolean(),
	currentDistributionChannels: Yup.array(),
	currentRetailChannels: Yup.array(),
	keheRegion: Yup.array(),
	otherDistributorRegion: Yup.array(),
	UNFIRegion: Yup.array()
});
export const DistributionFormComponent: React.FC<IDistributionForm> = ({
	brandState,
	onUpdateProfileBrand,
	onCancel,
	isEditing,
	toggleEditing,
	onChange,
	onValidationChange,
	cleanForm,
	updateKeyFc,
	profileState
}) => {
	const ID = 'distribution-channels';
	const [isVisible, setVisibility] = useState<boolean>(true);
	const [showRetails, setShowRetails] = useState<boolean>(true);
	const [showKehe, setShowKehe] = useState<boolean>(false);
	const [showUNFI, setShowUNFI] = useState<boolean>(false);
	const [showOther, setShowOther] = useState<boolean>(false);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};

	const {
		values,
		handleChange,
		handleReset,
		submitForm,
		isValid,
		errors,
		setValues,
		setFieldError
	} = useFormik({
		initialValues: {
			...brandState,
			mapPolicy: brandState.mapPolicy,
			currentDistributionChannels: brandState.currentDistributionChannels,
			currentRetailChannels: brandState.currentRetailChannels,
			keheRegion: brandState.keheRegion,
			otherDistributorRegion: brandState.otherDistributorRegion,
			UNFIRegion: brandState.UNFIRegion,
			cleanChannels: false
		},
		onSubmit: values => {
			onUpdateProfileBrand({
				...values,
				mapPolicy: values.mapPolicy,
				currentDistributionChannels: values.currentDistributionChannels,
				currentRetailChannels: values.currentRetailChannels,
				keheRegion: values.keheRegion,
				otherDistributorRegion: values.otherDistributorRegion,
				UNFIRegion: values.UNFIRegion
			});
			updateKeyFc();
		},
		validationSchema: DistributionSchema
	});
	const cleanInput = () => {
		const updatedItems = values.currentRetailChannels!.map(item => ({
			...item,
			isActive: false
		}));
		setValues({
			...values,
			currentRetailChannels: updatedItems
		});
		setShowRetails(false);
	};

	useEffect(() => {
		onChange(values);
	}, [values]);
	useEffect(() => {
		onValidationChange(isValid);
	}, [isValid]);

	useEffect(() => {
		handleReset(null);
	}, [cleanForm, brandState]);

	const onRegionsChange = (distributor: any, id: any) => {
		if (distributor.id !== 'NotInDistribution') {
			const updateChannels = values.currentDistributionChannels.map(item =>
				item.id === distributor.id
					? {
							...item,
							isActive: !distributor.isActive
					  }
					: item
			);
			const hasActiveChannels = updateChannels.some(item => item.isActive === true);
			console.log('update channels', updateChannels);
			const channels = updateChannels.map(item =>
				item.id === 'NotInDistribution'
					? {
							...item,
							isActive: !hasActiveChannels
					  }
					: item
			);
			const keheActive = channels.find(element => element.id === 'KeHe')?.isActive!;
			const unfiActive = channels.find(element => element.id === 'UNFI')?.isActive!;
			const otherActive = channels.find(element => element.id === 'Other')?.isActive!;
			let keheDistribution = values.keheRegion;
			let unfiDistribution = values.UNFIRegion;
			let otherDistribution = values.otherDistributorRegion;

			if (!keheActive) {
				keheDistribution = values.keheRegion.map(region => ({
					...region,
					isActive: false
				}));
			}
			if (!unfiActive) {
				unfiDistribution = values.UNFIRegion.map(region => ({
					...region,
					isActive: false
				}));
			}
			if (!otherActive) {
				otherDistribution = values.otherDistributorRegion.map(region => ({
					...region,
					isActive: false
				}));
			}
			setShowKehe(keheActive);
			setShowOther(otherActive);
			setShowUNFI(unfiActive);
			setValues({
				...values,
				currentDistributionChannels: channels,
				cleanChannels: hasActiveChannels,
				UNFIRegion: unfiActive ? values.UNFIRegion : unfiDistribution,
				keheRegion: keheActive ? values.keheRegion : keheDistribution,
				otherDistributorRegion: otherActive ? values.otherDistributorRegion : otherDistribution
			});
		} else {
			const updateChannels = values.currentDistributionChannels.map(item =>
				item.id === distributor.id
					? {
							...item,
							isActive: true
					  }
					: {
							...item,
							isActive: false
					  }
			);
			const keheDistribution = values.keheRegion.map(region => ({
				...region,
				isActive: false
			}));
			const unfiDistribution = values.UNFIRegion.map(region => ({
				...region,
				isActive: false
			}));
			const otherDistribution = values.otherDistributorRegion.map(region => ({
				...region,
				isActive: false
			}));
			setValues({
				...values,
				currentDistributionChannels: updateChannels,
				cleanChannels: false,
				keheRegion: keheDistribution,
				UNFIRegion: unfiDistribution,
				otherDistributorRegion: otherDistribution
			});
		}
	};
	useEffect(() => {
		const hasActiveChannels = values.currentDistributionChannels.some(
			item => item.isActive === true
		);
		if (hasActiveChannels) {
			const keheActive = values.currentDistributionChannels.find(element => element.id === 'KeHe')
				?.isActive!;
			const unfiActive = values.currentDistributionChannels.find(element => element.id === 'UNFI')
				?.isActive!;
			const otherActive = values.currentDistributionChannels.find(element => element.id === 'Other')
				?.isActive!;

			setShowKehe(keheActive);
			setShowOther(otherActive);
			setShowUNFI(unfiActive);
			setValues({
				...values,
				cleanChannels: hasActiveChannels,
				keheRegion: values.keheRegion,
				UNFIRegion: values.UNFIRegion,
				otherDistributorRegion: values.otherDistributorRegion
			});
		} else {
			const noChannels = values.currentDistributionChannels.map(item =>
				item.id === 'NotInDistribution'
					? {
							...item,
							isActive: true
					  }
					: item
			);
			setValues({
				...values,
				currentDistributionChannels: noChannels,
				cleanChannels: hasActiveChannels
			});
		}
	}, [values.currentDistributionChannels]);

	useEffect(() => {
		const keheActive = brandState.currentDistributionChannels.find(element => element.id === 'KeHe')
			?.isActive!;
		const unfiActive = brandState.currentDistributionChannels.find(element => element.id === 'UNFI')
			?.isActive!;

		const otherActive = brandState.currentDistributionChannels.find(
			element => element.id === 'Other'
		)?.isActive!;
		setShowKehe(keheActive);
		setShowOther(otherActive);
		setShowUNFI(unfiActive);
		setValues({
			...brandState,
			cleanChannels: false
		});
	}, [brandState]);
	useEffect(() => {
		const keheActive = values.currentDistributionChannels.find(element => element.id === 'KeHe')
			?.isActive!;
		const keheRegionsActive = values.keheRegion.some(region => region.isActive === true);
		const unfiActive = values.currentDistributionChannels.find(element => element.id === 'UNFI')
			?.isActive!;
		const unfiRegionsActive = values.UNFIRegion.some(region => region.isActive === true);
		const otherActive = values.currentDistributionChannels.find(element => element.id === 'Other')
			?.isActive!;
		const otherRegionsActive = values.otherDistributorRegion.some(
			region => region.isActive === true
		);
		setShowKehe(keheActive);
		setShowOther(otherActive);
		setShowUNFI(unfiActive);
		if (keheActive && !keheRegionsActive) {
			setFieldError('keheRegion', 'Please select at least one option.');
		}
		if (unfiActive && !unfiRegionsActive) {
			setFieldError('UNFIRegion', 'Please select at least one option.');
		}
		if (otherActive && !otherRegionsActive) {
			setFieldError('otherDistributorRegion', 'Please select at least one option.');
		}
	}, [values, errors, cleanForm]);
	useEffect(() => {
		if (isEditing) {
			const keheActive = brandState.currentDistributionChannels.find(
				element => element.id === 'KeHe'
			)?.isActive!;
			const unfiActive = brandState.currentDistributionChannels.find(
				element => element.id === 'UNFI'
			)?.isActive!;
			const otherActive = brandState.currentDistributionChannels.find(
				element => element.id === 'Other'
			)?.isActive!;
			setShowKehe(keheActive);
			setShowOther(otherActive);
			setShowUNFI(unfiActive);
			setValues({
				...brandState,
				mapPolicy: brandState.mapPolicy,
				currentDistributionChannels: brandState.currentDistributionChannels,
				currentRetailChannels: brandState.currentRetailChannels,
				keheRegion: brandState.keheRegion,
				otherDistributorRegion: brandState.otherDistributorRegion,
				UNFIRegion: brandState.UNFIRegion,
				cleanChannels: false
			});
		}
	}, [brandState, isEditing]);
	useEffect(() => {
		const hasActiveRetails = values.currentRetailChannels.some(item => item.isActive === true);
		setShowRetails(hasActiveRetails);
	}, [values.currentRetailChannels, brandState]);
	return (
		<div className='profile-distribution' id={ID}>
			<div className='distribution-tab'>
				<TitleForms
					isEditing={isEditing === ID}
					isVisible={isVisible}
					isValid={isValid}
					onUpdate={() => submitForm()}
					titleText='Distribution / Channels'
					toggleEditing={() => toggleEditing(ID)}
					toggleVisibility={toggleVisibility}
					profileState={profileState}
				></TitleForms>
				{ID === profileState.sectionId && profileState.loading ? (
					<LoadingBobComponent></LoadingBobComponent>
				) : (
					<>
						{isVisible &&
							(isEditing !== ID ? (
								<>
									<ContainerForms
										FormComponent1={
											<>
												<div>
													<LabelForms
														text={DEFAULT_LABELS.currentDistributionChannels}
													></LabelForms>
													{!values.currentDistributionChannels[3].isActive ? (
														brandState.currentDistributionChannels
															.filter(channel => channel.isActive === true)
															.map(distributor => (
																<CheckboxFormComponent
																	checkboxType='square'
																	key={'distributor' + distributor.id}
																	name={`currentDistributionChannels.${distributor.id}`}
																	checkboxText={<>{distributor.name}</>}
																	value={distributor.isActive}
																	disabled={true}
																	onClick={() => toggleEditing(ID)}
																></CheckboxFormComponent>
															))
													) : (
														<CheckboxFormComponent
															checkboxType='square'
															key={'distributor-none'}
															name={`cleanChannels`}
															checkboxText={<>Not In Distribution</>}
															value={values.currentDistributionChannels[3].isActive}
															disabled={true}
															onClick={() => toggleEditing(ID)}
														></CheckboxFormComponent>
													)}
												</div>
												{showKehe && (
													<div>
														<LabelForms text={DEFAULT_LABELS.keheRegion}></LabelForms>

														{brandState
															.keheRegion!.filter(region => region.isActive === true)
															.map(region => (
																<CheckboxFormComponent
																	key={region.id}
																	checkboxType='square'
																	onClick={() => toggleEditing(ID)}
																	name={`otherDistributorRegion.${region.id}`}
																	checkboxText={<>{region.name}</>}
																	value={region.isActive}
																	disabled={true}
																	onChange={handleChange}
																></CheckboxFormComponent>
															))}
													</div>
												)}
												{showUNFI && (
													<div>
														<LabelForms text={DEFAULT_LABELS.UNFIRegion}></LabelForms>
														{brandState
															.UNFIRegion!.filter(region => region.isActive === true)
															.map(region => (
																<CheckboxFormComponent
																	checkboxType='square'
																	key={'unfi' + region.id}
																	name={`UNFIRegion.${region.id}`}
																	checkboxText={<>{region.name}</>}
																	onClick={() => toggleEditing(ID)}
																	value={region.isActive}
																	disabled={true}
																	onChange={handleChange}
																></CheckboxFormComponent>
															))}
													</div>
												)}
											</>
										}
										FormComponent2={
											<>
												{' '}
												{showOther && (
													<div>
														<LabelForms text={DEFAULT_LABELS.otherDistributorRegion}></LabelForms>
														{brandState
															.otherDistributorRegion!.filter(region => region.isActive === true)
															.map(region => (
																<CheckboxFormComponent
																	checkboxType='square'
																	key={'other' + region.id}
																	name={`otherDistributorRegion.${region.id}`}
																	checkboxText={<>{region.name}</>}
																	onClick={() => toggleEditing(ID)}
																	value={region.isActive}
																	disabled={true}
																	onChange={handleChange}
																></CheckboxFormComponent>
															))}
													</div>
												)}
												<div>
													<LabelForms text={DEFAULT_LABELS.currentRetailChannels}></LabelForms>
													{showRetails ? (
														brandState
															.currentRetailChannels!.filter(region => region.isActive === true)
															.map(distributor => (
																<CheckboxFormComponent
																	key={'reatail' + distributor.id}
																	onClick={() => toggleEditing(ID)}
																	checkboxType='square'
																	name={`currentDistributionChannels.${distributor.id}`}
																	checkboxText={<>{distributor.name}</>}
																	value={distributor.isActive}
																	disabled={true}
																></CheckboxFormComponent>
															))
													) : (
														<CheckboxFormComponent
															checkboxType='square'
															key={'tech-none'}
															name={`cleanRetails`}
															checkboxText={
																<div className=''>
																	<p className='description-technique'>None</p>
																</div>
															}
															disabled
															value={!showRetails}
															onClick={() => toggleEditing(ID)}
														></CheckboxFormComponent>
													)}
												</div>
												<div>
													<LabelForms text={DEFAULT_LABELS.mapPolicy}></LabelForms>
													<CheckboxFormComponent
														checkboxType='toggle'
														name='mapPolicy'
														value={brandState.mapPolicy!}
														onClick={() => toggleEditing(ID)}
														disabled={true}
													></CheckboxFormComponent>
												</div>
											</>
										}
									></ContainerForms>
								</>
							) : (
								<>
									<ContainerForms
										FormComponent1={
											<>
												<div>
													<LabelForms
														text={DEFAULT_LABELS.currentDistributionChannels}
													></LabelForms>
													{values.currentDistributionChannels!.map((distributor, index) => (
														<CheckboxFormComponent
															key={distributor.id}
															checkboxType='square'
															name={`currentDistributionChannels.[${index}].isActive`}
															checkboxText={<>{distributor.name}</>}
															value={distributor.isActive}
															disabled={false}
															onChange={() => onRegionsChange(distributor, index)}
														></CheckboxFormComponent>
													))}
												</div>
												{showKehe && (
													<div>
														<LabelForms text={DEFAULT_LABELS.keheRegion}></LabelForms>
														{values.keheRegion!.map((region, index) => (
															<CheckboxFormComponent
																key={region.id}
																checkboxType='square'
																name={`keheRegion.[${index}].isActive`}
																checkboxText={<>{region.name}</>}
																value={region.isActive}
																disabled={false}
																onChange={handleChange}
															></CheckboxFormComponent>
														))}
														{errors.keheRegion && <p>{errors.keheRegion.toString()}</p>}
													</div>
												)}
												{showUNFI && (
													<div>
														<LabelForms text={DEFAULT_LABELS.UNFIRegion}></LabelForms>
														{values.UNFIRegion!.map((region, index) => (
															<CheckboxFormComponent
																key={region.id}
																checkboxType='square'
																name={`UNFIRegion.[${index}].isActive`}
																checkboxText={<>{region.name}</>}
																value={region.isActive}
																disabled={false}
																onChange={handleChange}
															></CheckboxFormComponent>
														))}
														{errors.UNFIRegion && <p>{errors.UNFIRegion.toString()}</p>}
													</div>
												)}
											</>
										}
										FormComponent2={
											<>
												{' '}
												{showOther && (
													<div>
														<LabelForms text={DEFAULT_LABELS.otherDistributorRegion}></LabelForms>
														{values.otherDistributorRegion!.map((region, index) => (
															<CheckboxFormComponent
																key={region.id}
																checkboxType='square'
																name={`otherDistributorRegion.[${index}].isActive`}
																checkboxText={<>{region.name}</>}
																value={region.isActive}
																disabled={false}
																onChange={handleChange}
															></CheckboxFormComponent>
														))}
														{errors.otherDistributorRegion && (
															<p>{errors.otherDistributorRegion.toString()}</p>
														)}
													</div>
												)}
												<div>
													<LabelForms text={DEFAULT_LABELS.currentRetailChannels}></LabelForms>
													{values.currentRetailChannels!.map((distributor, index) => (
														<CheckboxFormComponent
															key={distributor.id}
															checkboxType='square'
															name={`currentRetailChannels.[${index}].isActive`}
															checkboxText={<>{distributor.name}</>}
															value={distributor.isActive}
															disabled={false}
															onChange={handleChange}
														></CheckboxFormComponent>
													))}
													<CheckboxFormComponent
														key={'cleanInput'}
														checkboxType='square'
														name={``}
														checkboxText={<div className=''>None</div>}
														value={!showRetails}
														onChange={() => cleanInput()}
													></CheckboxFormComponent>
												</div>
												<div>
													<LabelForms text={DEFAULT_LABELS.mapPolicy}></LabelForms>
													<CheckboxFormComponent
														checkboxType='toggle'
														name='mapPolicy'
														value={values.mapPolicy}
														disabled={false}
														onChange={handleChange}
													></CheckboxFormComponent>
												</div>
											</>
										}
									></ContainerForms>
									<ActionsForms
										onUpdate={() => submitForm()}
										onCancel={() => {
											onCancel();
											handleReset(null);
											updateKeyFc();
										}}
										isValid={isValid}
										toggleEditing={() => toggleEditing(null)}
									></ActionsForms>
								</>
							))}
					</>
				)}
			</div>
		</div>
	);
};
