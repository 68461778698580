import { gql } from 'graphql.macro';

export const GET_BUSINESS_NAVBAR = gql`
	query {
		businessServicesNavbar {
			id
			name
		}
	}
`;
