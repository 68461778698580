import { cleaningRegex } from '../../../../common/constants';
import {
	Status,
	StatusBubbleComponent
} from '../../../UI/StatusBubbleComponent/StatusBubbleComponent';

interface BrandStatusComponentProps {
	brandStatus: string;
	brandName: string;
}
export const BrandStatusComponent: React.FC<BrandStatusComponentProps> = ({
	brandName,
	brandStatus
}) => {
	const brandStates = [
		{
			id: 'NEA - Pending',
			description: (
				<>
					Your most recent Standards application has been received, please check your email for
					further instructions from our Standards or Audience Success Teams.
				</>
			),
			name: 'Pending'
		},
		{
			id: 'REF - Pending',
			description: (
				<>
					Your most recent Standards application has been received, please check your email for
					further instructions from our Standards or Audience Success Teams.
				</>
			),
			name: 'Pending'
		},
		{
			id: 'Products Needed on Portal',
			description: (
				<>
					Your most recent Standards application has been received. Your next step is to upload your
					products into the New Hope Product Portal. Please contact our{' '}
					<a href='mailto:AudienceSucessTeam@NewHope.com' target='_blank' rel='noreferrer'>
						AudienceSuccessTeam@NewHope.com{' '}
					</a>
					if you need product onboarding assistance.
				</>
			),
			name: 'Products Needed on Portal'
		},
		{
			id: 'NEA - Approved',
			description: (
				<>
					Your most recent Standards application and at least one of your products have been
					approved. If you are exhibiting at an upcoming show and do not yet have your booth
					reservation, please contact your{' '}
					<a
						target='_blank'
						rel='noreferrer'
						href='https://www.expowest.com/en/Exhibitor-Resources/Exhibitor-Resources/contact-your-account-manager.html'>
						Account Manager
					</a>
					.
				</>
			),
			name: 'Live on Beacon'
		},
		{
			id: 'REF - Approved',
			description: (
				<>
					Your most recent Standards application and at least one of your products have been
					approved. If you are exhibiting at an upcoming show and do not yet have your booth
					reservation, please contact your{' '}
					<a
						rel='noreferrer'
						target='_blank'
						href='https://www.expowest.com/en/Exhibitor-Resources/Exhibitor-Resources/contact-your-account-manager.html'>
						Account Manager
					</a>
					.
				</>
			),
			name: 'Live on Beacon'
		},
		{
			id: 'NEA - Approved - Labeling Due',
			description: (
				<>
					Your most recent Standards application has been approved, however, additional information
					is required. Please contact{' '}
					<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
						Standards@newhope.com
					</a>{' '}
					for details.
				</>
			),
			name: 'Approved - Missing Product Info'
		},
		{
			id: 'NEA - Incomplete',
			description: (
				<>
					Your most recent Standards application is Incomplete. Please contact{' '}
					<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
						Standards@newhope.com
					</a>{' '}
					for details.
				</>
			),
			name: 'Incomplete'
		},
		{
			id: 'REF - Incomplete',
			description: (
				<>
					Your most recent Standards application is Incomplete. Please contact{' '}
					<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
						Standards@newhope.com
					</a>{' '}
					for details.
				</>
			),
			name: 'Incomplete'
		},
		{
			id: 'NEA - Not Approved',
			description: (
				<>
					Your most recent Standards application was not approved. Please contact{' '}
					<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
						Standards@newhope.com
					</a>{' '}
					for details.
				</>
			),
			name: 'Not Approved'
		},
		{
			id: 'MUST REAPPLY to exhibit',
			description: (
				<>
					Your most recent Standards application has been closed because it is outdated and you must
					complete our new application form. Please contact{' '}
					<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
						Standards@newhope.com
					</a>{' '}
					before starting a new application.
				</>
			),
			name: 'Inactive'
		},
		{
			id: 'NEA - Closed',
			description: (
				<>
					Your most recent Standards application has been closed because it is outdated and/or your
					company does not have a current Beacon Subscription. Please contact{' '}
					<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
						Standards@newhope.com
					</a>{' '}
					before starting a new application.
				</>
			),
			name: 'Inactive'
		}
	];

	const cleanedStatus = brandStatus.toLowerCase().replace(cleaningRegex, '').trim();
	const normalizedBrandStatus = brandStates.map(state => ({
		...state,
		id: state.id.toLowerCase().replace(cleaningRegex, '').trim()
	}));
	const currentStatusBrand = normalizedBrandStatus.find(status => status.id === cleanedStatus);
	return (
		<>
			<div className='title-container'>
				<StatusBubbleComponent status={currentStatusBrand?.name as Status}></StatusBubbleComponent>
				<h3>{brandName}</h3>
			</div>
			<div className='app-status'>
				<span>Application Status: </span>
				<span>{currentStatusBrand?.description}</span>
			</div>
		</>
	);
};
