import { useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { GET_BUSINESS_BRAND_BY_CATEGORY } from '../../graphql/queries/businessProvider';
import { Brand } from '../../common/model';
import './index.scss';
import { DEFAULT_LABELS, DUMMIE_BRANDS, DUMMIE_PROVIDERS } from './model';

export interface IBusinessServices {
	name: string;
	id: number;
	brands: any;
}

export const BusinessProvidersPage: FC = () => {
	const [providers, setProviders] = useState<any>([]);
	const [seachrParams] = useSearchParams();
	const category = Number(seachrParams.get('category'));
	const { loading, error, data } = useQuery(GET_BUSINESS_BRAND_BY_CATEGORY, {
		variables: { id: category }
	});
	const [totalProviders, setTotalProviders] = useState<number>(0);
	useEffect(() => {
		if (!error && !loading && data?.brandsByBusinessService) {
			const { __typename, ...rest } = data.brandsByBusinessService;
			//setBrands(rest);
		}
		// if (error?.message === 'Unauthorized') {
		// 	props.handleUnauthorized();
		// }
		if (error) {
			console.log(error);
		}
	}, [data, error, loading]);
	useEffect(() => {
		const dummieData: any = DUMMIE_PROVIDERS.data.brandsByBusinessService;
		const tempBrands: any = DUMMIE_BRANDS;
		dummieData.map((element: any) => {
			const brands = [];
			for (let index = 0; index < 25; index++) {
				let brandIndex = Math.floor(Math.random() * tempBrands.length);
				let tempObject = {
					id: tempBrands[brandIndex].id,
					name: tempBrands[brandIndex].name
				};
				brands.push(tempObject);
			}
			element.brands = brands.sort((a, b) => a.name.localeCompare(b.name));
		});
		setProviders(dummieData);
	}, []);
	return (
		<>
			{providers && (
				<div className='list-providers-container'>
					<h3 className='section-title'>{DEFAULT_LABELS.sectionTitle}</h3>
					<div className='providers-container'>
						<span className='providers-count'>{`${totalProviders} ${DEFAULT_LABELS.totalProviders}`}</span>
						{providers.map((provider: IBusinessServices, indexProvider: any) => (
							<div className='provider-section' key={`provider_${indexProvider}`}>
								<div className='category-title'>
									<h3 className='title'>{provider.name}</h3>
								</div>
								<div className='brands-container'>
									<div className='group'>
										{provider.brands.map((brand: any, indexBrand: number) => {
											return (
												<div
													className='brand'
													key={indexBrand}
													data-test={`provider_${indexProvider}_${indexBrand}`}
												>
													<Link to={`/brandprovider/${brand.id}`} className='text'>
														{brand.name}
													</Link>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};
