import React, { FC, useCallback, useEffect, useState } from 'react';
import { BrandVM, TrackingEventStatus, TrackingEventType } from '../../../common/view-model';
import { RootState } from '../../../redux/AppReducer';
import { connect } from 'react-redux';
import { aboutBrandState } from '../model';
import { Events } from '../../../common/model';
import { AppActions } from '../../../redux/AppActions';
import { ExpoEastOverlay } from '../../ExpoEastOverlay/ExpoEastOverlay';
import { handleTracking } from '../../../helpers/handelTracking';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import ShareMenuComponent from '../../shareMenu/shareMenuComponent';
import { BoothNumberComponent } from '../../UI/BoothNumberComponent/BoothNumberComponent';
import { ExpoLabelComponent } from '../../UI/ExpoLabelComponent/ExpoLabelComponent';
import BrandAttributesComponent from '../brand-attributes/BrandAttributesComponent';
import { useDispatch } from 'react-redux';
import './BrandHeaderComponent.scss';
import { Action, Dispatch } from 'redux';
import { UserActions } from '../../../redux/UserActions';
import { validateUrl } from '../../../helpers/validateUrl';
import StarLogo from '../../../assets/images/attributes-decorator.svg';

interface IBrandHeaderComponentProps {
	brand: BrandVM;
	currentEvent?: Events | null;
	registerUserClickEvent: (event: string) => void;
	showShareMenu: boolean;
}

interface ILinksComponentProps {
	brand: BrandVM;
	isMobile?: boolean;
}

const LinksComponent: FC<ILinksComponentProps> = ({ brand, isMobile }) => {
	return (
		<div className={`demo-link-container ${isMobile ? 'mobile' : ''}`}>
			{brand.demo_link && (
				<div className='item'>
					<i className='ri-external-link-line'></i>
					<a
						data-testid='link-content'
						className='attribute-link'
						href={validateUrl(brand.demo_link!)}
						target='_blank'
					>
						See A Product Demo
					</a>
				</div>
			)}
			{brand.sales_sheets && (
				<div className='item'>
					<i className='ri-external-link-line'></i>
					<a
						data-testid='link-content'
						className='attribute-link'
						href={validateUrl(brand.sales_sheets!)}
						target='_blank'
					>
						Sales Sheets
					</a>
				</div>
			)}
		</div>
	);
};

const BrandHeaderComponent: FC<IBrandHeaderComponentProps> = ({
	brand,
	currentEvent,
	registerUserClickEvent,
	showShareMenu
}) => {
	const [state] = useState({ ...aboutBrandState });
	const userState = useSelector((state: RootState) => state.appState.userState?.user);
	const dispatch = useDispatch();
	const [width, setWidth] = useState(window.innerWidth - 1);

	useEffect(() => {
		const handleResize = () => {
			let tempWidth = document.querySelector('[data-testid="navbar"]')?.scrollWidth;
			setWidth(tempWidth! - 1);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const openExpoPopUpCallback = useCallback(() => {
		dispatch(
			AppActions.setModalContent({
				content: <ExpoEastOverlay />,
				showCloseIcon: false,
				canScroll: true,
				closeOnDocumentClick: true
			})
		);
		handleTracking({
			transactionId: uuidv4(),
			entityId: brand?.id!,
			status: TrackingEventStatus.SUCCESS,
			type: TrackingEventType.CLICK_ON_SEE_US_EXPO_BUTTON,
			timeStamp: new Date().toISOString(),
			eventPayload: {
				id: currentEvent?.id!,
				name: currentEvent?.name!,
				type: brand?.name!,
				url: window.location.href,
				tags: [],
				metadata: {
					user_id: userState?.id,
					user_type: userState?.user_type,
					environment: process.env.REACT_APP_ENV_NAME,
					event_id: currentEvent?.id,
					event_name: currentEvent?.name
				}
			}
		});
	}, [dispatch]);
	return (
		<>
			<div className='brand-profile'>
				<div className='brand-profile-name'>
					<span className='brand-name'>{brand.name}</span>
				</div>

				<div className='brand-information'>
					<div className='brand-image'>
						<div className='image-container'>
							<img src={brand.url_logo} alt='brand' />
							{showShareMenu && (
								<div className='share-container'>
									<ShareMenuComponent id={brand.id} type='brand'></ShareMenuComponent>
								</div>
							)}
						</div>
					</div>
					<div className='brand-attributes-container'>
						{brand.boothNumber && currentEvent && currentEvent != null && (
							<>
								<BoothNumberComponent
									text={`Booth #${brand.boothNumber}`}
									boothStyle='primary'
									icon='ri-store-3-line'
								/>
								<ExpoLabelComponent
									text={`See us at ${currentEvent?.label || currentEvent.name}!`}
									labelStyle={`large ${currentEvent?.name
										.replace(/\s+/g, '')
										.toLocaleLowerCase()
										.trim()}`}
									icon=''
									showAnimation={true}
									onClick={openExpoPopUpCallback}
								/>
							</>
						)}
						<div className='brand-attributes'>
							<div className='attributes-container'>
								{brand.attributes.map((attribute, index) => {
									return (
										<BrandAttributesComponent
											key={index}
											{...attribute}
											registerUserClickEvent={registerUserClickEvent}
										/>
									);
								})}
							</div>
						</div>
						{(brand.demo_link || brand.sales_sheets) && (
							<LinksComponent brand={brand} isMobile={false}></LinksComponent>
						)}
					</div>
				</div>

				{(brand.demo_link || brand.sales_sheets) && (
					<LinksComponent brand={brand} isMobile={true}></LinksComponent>
				)}

				<div className='container-slider-certifications' style={{ width: width }}>
					{brand?.certifications && (
						<div className='slider-certifications'>
							<div className='group'>
								{[...Array(5)].map((_, i) => (
									<React.Fragment key={`group_${i}`}>
										{brand.certifications?.map((certification, index) => (
											<React.Fragment key={`certification_${i}_${index}`}>
												<div className='attribute'>{certification}</div>
												<img src={StarLogo} alt='img' />
											</React.Fragment>
										))}
									</React.Fragment>
								))}
							</div>
						</div>
					)}
				</div>

				{(brand.brand_mission || brand.brand_story) && (
					<div className='brand-details'>
						<div className={`mission-container  no-card `}>
							{brand.brand_mission && (
								<div className='mission-item'>
									<span className='brand-info-title'>{state.missionStatementTitleText}</span>
									<p className='brand-mission'>{brand.brand_mission}</p>
								</div>
							)}
							{brand.brand_story && (
								<div className='mission-item'>
									<span className='brand-info-title'>{state.ourStoryTitleText}</span>
									<p className='brand-story'>{brand.brand_story}</p>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = ({ appState: state }: RootState) => ({
	currentEvent: state.storeState?.getCurrentEvent
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	registerUserClickEvent: (event: string) => {
		dispatch(UserActions.registerUserClickEvent(event));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandHeaderComponent);
