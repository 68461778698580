import { FC, useEffect, useState } from 'react';
import './ExpoLabelComponent.scss';

export interface IExpoLabelComponentProps {
	text: string;
	labelStyle?: string;
	onClick?: () => void;
	icon?: string;
	showAnimation?: boolean;
	duration?: number;
	delay?: number;
}

export const ExpoLabelComponent: FC<IExpoLabelComponentProps> = ({
	text,
	labelStyle,
	icon,
	duration = 450,
	delay = 5000,
	showAnimation
}) => {
	const [isAnimated, setIsAnimated] = useState(false);
	useEffect(() => {
		let animationTime: NodeJS.Timeout;
		let delayTime: NodeJS.Timeout;
		const startAnimation = () => {
			setIsAnimated(true);
			animationTime = setTimeout(() => {
				setIsAnimated(false);
				delayTime = setTimeout(startAnimation, delay);
			}, duration);
		};
		startAnimation();
		return () => {
			clearTimeout(animationTime);
			clearTimeout(delayTime);
		};
	}, [duration, delay]);

	return (
		<div
			data-testid='expo-label-component'
			className={`expo-label ${labelStyle} ${showAnimation && isAnimated ? 'animated' : ''}`}>
			<span id='expo-label-component'>
				{text}
				{icon && <i className={icon}></i>}
			</span>
		</div>
	);
};
