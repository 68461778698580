export const DEFAULT_PLACEHOLDERS = {
	brand_video: 'www.brandvideo.com',
	city: 'Los Angeles',
	choose_list: 'Choose from the list below',
	company_name: 'Company Name ',
	email: 'email@address.com',
	name: 'First Name Last Name',
	phone_number: '1 123-456-7890',
	job_title: 'Job Title',
	facebook_link: 'https://www.facebook.com/profilename',
	instagram_link: 'https://www.instagram.com/profilename',
	linkedin_link: 'https://www.linkedin.com/in/profilename',
	x_link: 'https://x.com/profilename',
	youtube_link: 'https://www.youtube.com/@profilename',
	tiktok_link: 'https://www.tiktok.com/@profilename',
	currency: '1,000,000'
};
