import { ErrorState } from '../redux/AppReducer';

export const APP_NAME = 'Beacon Discovery™';
export const factPanelIdentifier = /(fact(\s*panel|\-panel|panel)?|nutrition)/i;
export const serverError: ErrorState = {
	hasError: true,
	errorTitle: 'Something went wrong on our end, let’s get you back to discovering.',
	errorImg: require('../assets/images/ErrorState404.png')
};
export const beaconFaqLink = 'https://beacon.newhope.com/faq/';
export const eventPublicPathName = 'public';
export const expoWestLink = 'https://www.expowest.com';
export const newtopiaLink = 'https://www.newtopianow.com/en/home.html';
export const convertUserLocalStorageKey = 'convert-user';
export const publicProductDiscoveryActions = {
	CLICK_OPEN_FILTER: 'CLICK_PRODUCT_DISCOVERY_OPEN_FILTER',
	CLICK_FILTER_CHECKBOX: 'CLICK_PRODUCT_DISCOVERY_FILTER_CHECKBOX',
	CLICK_CATEGORY_TOP_NAVBAR: 'CLICK_PRODUCT_DISCOVERY_CATEGORY_TOP_NAVBAR',
	CLICK_INSIDE_TOP_NAVBAR: 'CLICK_PRODUCT_DISCOVERY_INSIDE_TOP_NAVBAR',
	CLICK_HEART_ICON: 'CLICK_PRODUCT_DISCOVERY_HEART_ICON',
	CLICK_SORT_BY: 'CLICK_PRODUCT_DISCOVERY_SORT_BY',
	SEARCH_MADE: 'CLICK_PRODUCT_DISCOVERY_SEARCH_MADE',
	CLICK_PRODUCT: 'CLICK_PRODUCT_DISCOVERY_PRODUCT_CARD',
	CLICK_INFO_NOT_AVAILABLE_MODAL: 'CLICK_PRODUCT_DISCOVERY_INFO_NOT_AVAILABLE_MODAL'
};
export const publicProductDetailsActions = {
	CLICK_PRODUCT_ACCORDION: 'CLICK_PRODUCT_DETAILS_PRODUCT_ACCORDION',
	CLICK_PRODUCT_IMAGE_CAROUSEL: 'CLICK_PRODUCT_DETAILS_PRODUCT_IMAGE_CAROUSEL',
	CLICK_HEART_ICON: 'CLICK_PRODUCT_DETAILS_HEART_ICON',
	CLICK_HG_SCORE: 'CLICK_PRODUCT_DETAILS_HG_SCORE',
	CLICK_REQUEST_SAMPLE: 'CLICK_PRODUCT_DETAILS_REQUEST_SAMPLE',
	CLICK_CONTACT_BRAND: 'CLICK_PRODUCT_DETAILS_CONTACT_BRAND',
	CLICK_CERTIFICATION_CARD: 'CLICK_PRODUCT_DETAILS_CERTIFICATION_CARD',
	CLICK_GO_TO_BRAND_PAGE: 'CLICK_PRODUCT_DETAILS_GO_TO_BRAND_PAGE'
};
export const publicBrandDetailActions = {
	CLICK_BRAND_LINKS: 'CLICK_BRAND_DETAIL_BRAND_LINKS',
	CLICK_SEE_ALL_PRODUCTS: 'CLICK_BRAND_DETAIL_SEE_ALL_PRODUCTS',
	CLICK_HEART_ICON: 'CLICK_BRAND_DETAIL_HEART_ICON'
};
export const publicHomePageActions = {
	CLICK_ALL_CATEGORIES: 'CLICK_ALL_CATEGORIES',
	CLICK_CATEGORY_BUBBLE: 'CLICK_CATEGORY_BUBBLE',
	CLICK_NEW_TO_BEACON: 'CLICK_NEW_TO_BEACON',
	CLICK_NEW_TO_MARKET: 'CLICK_NEW_TO_MARKET'
};
export const lastUrlLocalStorageKey = 'lastUrl';
export const redirectUrl = 'redirect_url';
export const newHopeAudienceEmail = 'AudienceSuccessTeam@newhope.com';

export const validationMessages = {
	field_required: 'Field is required.',
	min_chars: 'Text too short.',
	max_value: 'Max value must be',
	invalid_url: 'Enter correct URL.',
	exact_length: 'Must be exactly',
	invalid_email: 'Please input a valid email.'
};

export const validationRegex = {
	url_regex:
		/^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/g
};

export const cleaningRegex = /[^a-záéíóúüñ]/gi;

export const processingTechniques = [
	{
		id: 'Hydrogenotrophy',
		description:
			'uses hydrogenotrophic microbes fermented with a mix of carbon dioxide, oxygen, minerals, water, and nitrogen to produce complex proteins or oils.',
		name: 'Hydrogenotrophy'
	},
	{
		id: 'Cultivated animal cells',
		description:
			'animal meat, seafood, fat or secreted food products (e.g. milk) produced via growth of animal cells in a controlled and aseptic bioreactor environment (Note: products derived using this method are not currently legal for sale in the U.S.).',
		name: 'Cultivated animal cells'
	},
	{
		id: 'Extrusion',
		description:
			'uses temperature and force to push mixed ingredients out through a small opening, a die, to restructure proteins to create plant-based meat/seafood textures.',
		name: 'Extrusion (High-moisture or wet extrusion)'
	},
	{
		id: 'Fat Analog',
		description:
			'food products ground down to varying particle sizes in order to achieve the appearance, technological, rheological and sensorial properties for use as raw materials to replace animal fats.',
		name: 'Fat Analog'
	},
	{
		id: 'Precision Fermentation',
		description:
			'a kind of synthetic biology that uses engineered microbial hosts as \'"cell factories\'" to produce specific functional ingredients.',
		name: 'Precision Fermentation'
	},
	{
		id: 'Shear Cell',
		description:
			'similar to extrusion, this process relies on applying mechanical, thermal and shear stresses to a protein mixture to obtain a semi-solid fibrous structure producing a whole-cut products.',
		name: 'Shear Cell Technology'
	},
	{
		id: 'Synthetic biology',
		description:
			'a field of science that involves designing organisms for specific purposes by engineering them to have new abilities or functions the organism would not have naturally.',
		name: 'Synthetic Biology'
	},
	{
		id: 'Biomass fermentation',
		description: 'microbes with high-protein content cultivated for use as an ingredient.',
		name: 'Biomass Fermentation'
	},
	{
		id: 'Traditional fermentation',
		description:
			'changing a food through natural-occurring microbial digestion to make a fermented product such as sauerkraut, beer, kimchi, kombucha, where both the microbes and their byproducts are consumed.',
		name: 'Traditional Fermentation'
	},
	{
		id: 'Heat/Cold applications',
		description: 'spray- or freeze-drying, dehydration, cryogenics, etc.',
		name: 'Heat/Cold applications'
	},
	{
		id: 'Preservation methods',
		description:
			'flash or high pressure pasteurization (HPP), hydrogenation, acidification, solid dispersion, smoking, homogenization, irradiation, aseptic, etc.',
		name: 'Preservation methods'
	},
	{
		id: 'Extraction/Distillation/Filtration methods',
		description: 'cold-pressed, CO2 extraction, vacuum distillation, etc.',
		name: 'Extraction/Distillation methods'
	},
	{
		id: 'Other - Write In (Required)',
		description: 'Other Techniques',
		name: 'Other'
	}
	// {
	// 	id: 'None or Unknown',
	// 	description: 'None or Unknown',
	// 	name: 'None or Unknown'
	// }
];

export const productStatusArray = [
	{ id: 'Product Not on Portal', description: '', name: '' },
	{ id: 'Product Not Approved', description: '', name: 'Not Approved - See Violations' },
	{
		id: 'Do Not Publish Approved Product',
		description:
			'This product is approved but hidden from Beacon Discovery. Please contact Standards@newhope.com to make this product visible.',
		name: 'Not Published'
	},
	{ id: 'Product Approved', description: '', name: 'Approved' },
	{ id: 'Needs Review', description: '', name: 'Pending' },
	{ id: 'Revised - Needs Review', description: '', name: 'Pending' }
];

export const distributorsArray = [
	{ id: 'KeHe', name: 'KeHe' },
	{ id: 'UNFI', name: 'UNFI' },
	{ id: 'Other', name: 'Other' },
	{ id: 'NotInDistribution', name: 'Not In Distribution' }
];

export const retailChannelsArray = [
	{ id: 'conventionalRetailers', name: 'Conventional Retailers' },
	{ id: 'naturalChain', name: 'Natural Chain' },
	{ id: 'independent', name: 'Independent' },
	{ id: 'directConsumers', name: 'Direct Consumers' },
	{ id: 'eTailer', name: 'eTailer' }
];

export const regionsArray = [
	{ id: 'northEast', name: 'North East' },
	{ id: 'east', name: 'East' },
	{ id: 'southEast', name: 'South East' },
	{ id: 'central', name: 'Central' },
	{ id: 'west', name: 'West' }
];

export const PROFILE_ROUTES = {
	PROFILE: 'profile',
	INGREDIENTS: 'ingredients',
	FAVORITES: 'favorites',
	ONBOARD_FAQ: 'onboardFAQ',
	GET_ON_BEACON: 'getonbeacon',
	BRAND_INFO: 'brandInfo',
	PRODUCT_INFO: 'productInfo',
	BEACON_HUB: 'brandOnBeacon'
};

export const infoAttributes = [
	'Allergy free',
	'B Corp',
	'Convenience',
	'Cruelty-Free (Animal friendly)',
	'Disabled-Owned',
	'Ethnic/Multicultural',
	'Food Service Ready',
	'Functional Food or Beverage',
	'Halal',
	'Kids (Non-Food Related Kids Products)',
	'Kosher/Parve',
	'LGBTQ+ Owned',
	'Minority (or BIPOC)-Owned',
	'Organic Ingredients',
	'Specialty/Gourmet',
	'Sustainability',
	'Vegan Friendly',
	'Veteran-Owned',
	'Women-Owned'
];
