import { ICategoryNavbar } from '../common/view-model';
import agenda from '../assets/images/category-navbar/agenda.svg';
import exhibitor from '../assets/images/category-navbar/exhibitor.svg';
import exhibitorList from '../assets/images/category-navbar/exhibitorList.svg';
import newToBeacon from '../assets/images/category-navbar/newToBeacon.svg';
import newToMarket from '../assets/images/category-navbar/newToMarket.svg';
import regenerative from '../assets/images/category-navbar/regenerative.svg';
import showFloorMap from '../assets/images/category-navbar/showFloorMap.svg';
import womenOwned from '../assets/images/category-navbar/womenOwned.svg';
import nextyFinalists from '../assets/images/category-navbar/nextyFinalists.svg';
export const categoryNavbarElements: ICategoryNavbar[] = [
	{
		id: 'event',
		name: 'Expo West',
		elements: [
			{
				id: 'event-map',
				name: 'Show Floor Map',
				description: 'Start planning your show with our detailed floor plans by venue area.',
				imagePath: showFloorMap,
				linkUrl: undefined
			},
			{
				id: 'hub',
				name: 'Expo West',
				description:
					'Discover everything that makes the Newtopia Now experience purposefully different.',
				imagePath: exhibitor
			},
			{
				id: 'agenda',
				name: 'Agenda',
				description:
					'View the complete schedule of show floor hours, education sessions and unique community experiences.',
				imagePath: agenda
			},
			{
				id: 'exhibitor-list',
				name: 'Exhibitor List',
				description:
					'Search our list of exhibitors, featuring emerging and established CPG brands and service providers.',
				imagePath: exhibitorList
			}
		]
	},
	{
		id: 'new',
		name: "What's New?",
		elements: [
			{
				id: 'new-to-market',
				name: 'New to Market',
				description: 'Products or brands that launched within the last year.',
				imagePath: newToMarket
			},
			{
				id: 'women-owned',
				name: 'Women-Owned Brands',
				description:
					'Includes products with packaging claims indicating and/or certifications validating a business is at least 51 percent owned, controlled, operated, and managed by a woman or women. See product detail page for certifications.',
				imagePath: womenOwned
			},
			...(process.env.REACT_APP_USE_NEW_BEACON === 'true'
				? [
						{
							id: 'new-to-beacon',
							name: 'New to Beacon',
							description: 'Products added to Beacon Discovery within the last 30 days.',
							imagePath: newToBeacon
						}
				  ]
				: [
						{
							id: 'nexty-finalists',
							name: 'NEXTY Finalists',
							description:
								'The NEXTY Awards recognize the most progressive, innovative, inspiring and trustworthy products in the natural products industry.',
							imagePath: nextyFinalists
						}
				  ]),
			{
				id: 'certified-regenerative',
				name: 'Certified Regenerative',
				description:
					'A certification that demonstrates a commitment to the environment and local communities.',
				imagePath: regenerative
			}
		]
	},
	//{ id: 'business', name: 'Business Providers', elements: [] },
	{
		id: 'pantry',
		name: 'Pantry',
		elements: [
			{
				id: '99999991',
				panelFilterId: '991175',
				filters: []
			}
		]
	},
	{
		id: 'beverages',
		name: 'Beverages',
		elements: [
			{
				id: '99999992',
				panelFilterId: '991175',
				filters: []
			}
		]
	},
	{
		id: 'fresh-frozen',
		name: 'Fresh & Frozen',
		elements: [
			{
				id: '99999993',
				panelFilterId: '991175',
				filters: []
			}
		]
	},
	{
		id: 'supplements-vitamins',
		name: 'Supplements & Vitamins',
		elements: [
			{
				id: '99999994',
				panelFilterId: '88801232',
				filters: []
			}
		]
	},
	{
		id: 'health-beauty',
		name: 'Health & Beauty',
		elements: [
			{
				id: '99999995',
				panelFilterId: '991177',
				filters: []
			}
		]
	},
	{
		id: 'other',
		name: 'Other',
		elements: [
			{
				id: '991178',
				panelFilterId: '991178',
				filters: []
			}
		]
	}
];
