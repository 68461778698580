export const DEFAULT_LABELS = {
	sectionTitle: 'Discover Providers By Category',
	totalProviders: 'business providers'
};

export const DUMMIE_PROVIDERS = {
	data: {
		brandsByBusinessService: [
			{
				id: '2',
				name: 'Advertising/PR/Media/Marketing Agencies',
				brands: [
					{
						id: '13312',
						name: 'Lorem Ipsum'
					}
				]
			},
			{
				id: '1',
				name: 'Co-Manufacturing',
				brands: [
					{
						id: '24244',
						name: '18 Chestnuts Vegan Soups'
					}
				]
			}
		]
	}
};

export const DUMMIE_BRANDS = [
	{
		name: '18 Chestnuts Vegan Soups',
		id: '1686144394_6480858a6a28b5.04102964'
	},
	{
		name: '49th Parallel Coffee Roasters',
		id: '1686027238_647ebbe6b92556.58910571'
	},
	{
		name: `Abe's Vegan Muffins`,
		id: '1687534239_6495ba9f9d19c3.04401946'
	},
	{
		name: 'ACQUAROMA',
		id: '1700138873_65560f79b07cc9.03780640'
	},
	{
		name: 'AGRO POWER Jerky',
		id: '1717599814_66607e460e5017.27220913'
	},
	{
		name: 'Albanese World’s Best®',
		id: '1692810598_64e63d669203e8.31282283'
	},
	{
		name: 'Back to Nature',
		id: '1708105739_65cfa00b2c2da4.21952738'
	},
	{
		name: 'BareOrganics Superfoods',
		id: '1715788730_6644dbbacd3b40.93758545'
	},
	{
		name: 'Blue Zones Kitchen',
		id: '1683128960_6452828025a837.78415328'
	}
];
