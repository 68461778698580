import { FC, useEffect } from 'react';
import { FilterVM, ICategoryNavbar, NavbarElementType } from '../../../common/view-model';
import './NavbarCategoryComponent.scss';
import { NavbarOverlayComponent } from '../navbar-overlay/NavbarOverlayComponent';
import { isCurrentUserAnonymous } from '../../../helpers/authenticationHelper';
import { publicProductDiscoveryActions } from '../../../common/constants';
import cn from 'classnames';

interface INavbarCategoryComponentProps {
	handleAddCategoryFilter: (filter: FilterVM) => void;
	navbarCategoryElements: ICategoryNavbar[];
	categoryTab?: ICategoryNavbar;
	setCategoryTab: (category?: ICategoryNavbar) => void;
	setCloseOverlay: (setCurrentCategory: () => void) => void;
	handleAddFilterFromNavbar: (filter: FilterVM[]) => void;
	handleAddBizFromNavbar: (filter: FilterVM[]) => void;
	registerUserClickEvent: (event: string) => void;
	hasClickedOutsideBar: boolean;
	eventName: string;
	user: any;
	productsPageUrl: string;
	allCategories: FilterVM[];
}

export const NavbarCategoryComponent: FC<INavbarCategoryComponentProps> = ({
	handleAddCategoryFilter,
	navbarCategoryElements,
	categoryTab,
	setCategoryTab,
	setCloseOverlay,
	handleAddFilterFromNavbar,
	handleAddBizFromNavbar,
	registerUserClickEvent,
	hasClickedOutsideBar,
	eventName,
	user,
	productsPageUrl,
	allCategories
}) => {
	useEffect(() => {
		setCloseOverlay(() => setCategoryTab(undefined));
	}, [setCategoryTab, setCloseOverlay]);
	const handleCategoryNavbarClick = (element: ICategoryNavbar) => {
		setCategoryTab(element);
		if (isCurrentUserAnonymous()) {
			registerUserClickEvent(publicProductDiscoveryActions.CLICK_CATEGORY_TOP_NAVBAR);
		}
	};
	const customClasses = (element: ICategoryNavbar) => {
		return cn('category-item', {
			'event-text': element.id === NavbarElementType.EVENT,
			active: categoryTab?.id == element.id
		});
	};
	return (
		<>
			<div className='category-navbar'>
				{navbarCategoryElements.map((element: ICategoryNavbar) =>
					element.id !== NavbarElementType.EVENT &&
					element.id !== NavbarElementType.BUSINESS_SERVICE ? (
						<span
							className={customClasses(element)}
							key={element.id}
							onClick={() => handleCategoryNavbarClick(element)}
						>
							{element.name}
						</span>
					) : null
				)}
			</div>
			{categoryTab && (
				<NavbarOverlayComponent
					handleAddCategoryFilter={handleAddCategoryFilter}
					categoryTab={categoryTab}
					setCategoryTab={setCategoryTab}
					handleAddFilterFromNavbar={handleAddFilterFromNavbar}
					registerUserClickEvent={registerUserClickEvent}
					handleBizFilterFromNavbar={handleAddBizFromNavbar}
					hasClickedOutsideBar={hasClickedOutsideBar}
					eventName={eventName}
					user={user}
					productsPageUrl={productsPageUrl}
					allCategories={allCategories}
				/>
			)}
		</>
	);
};
